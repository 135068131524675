<template>
  <div>
    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo
          v-if="$store.state.token == 'INVALID'"
          :msg="$t('HelpPositieMagneetsluiting.Particulier.Info01')"
        />
        <BaseInfo
          v-if="$store.state.token != 'INVALID'"
          :msg="$t('HelpPositieMagneetsluiting.Dealer.Info01')"
        />
      </div>
      <div class="col-6">
        <img src="@/assets/Help/HelpMagneetsluiting.png" class="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpMagneetsluiting",
};
</script>
