<template>
  <div class="border-top p-0 ms-3 pt-2 mb-3 mt-5">
    <h2 class="font-egdl fw-bold fs-6">
      {{ $t(msg) }}
      <span v-if="this.showQuestion">
        <ButtonQuestion
          v-on:click.prevent="toggleHelp()"
          :isClosed="!this.$store.state.helpProperty[this.helpProp]"
        />
      </span>
    </h2>
  </div>
</template>
<script>
export default {
  name: "SubTitle",
  props: {
    msg: String,
    showQuestion: Boolean,
    helpProp: String,
  },
  methods: {
    toggleHelp() {
      if (this.helpProp) {
        this.$store.commit("toggleHelpProperty", this.helpProp);
      }
    },
  },
};
</script>
