<template>
  <div>
    <div v-if="$store.state.token == 'INVALID'">
      <BaseInfo :msg="$t('HelpLakafwerking.Particulier.Info01')" />
      <BaseInfo :msg="$t('HelpLakafwerking.Particulier.Info02')" />
    </div>
    <div v-if="$store.state.token != 'INVALID'">
      <BaseInfo :msg="$t('HelpLakafwerking.Dealer.Info01')" />
      <BaseInfo :msg="$t('HelpLakafwerking.Dealer.Info02')" />
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpLakafwerking",
};
</script>
