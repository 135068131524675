<template>
  <div v-if="isLoading" class="loading-screen">
    <svg width="50" height="50" viewBox="0 0 50 50">
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="blue"
        stroke-width="5"
        stroke-dasharray="80, 150"
        stroke-dashoffset="0"
        stroke-linecap="round"
      >
        <animateTransform
          attributeType="XML"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="1s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
    Loading....
  </div>
  <div v-else>
    <router-view />
    <div class="small text-end px-5 hideOnPrint">
      version {{ major }}.{{ minor }}.{{ build }}
    </div>
  </div>
</template>

<script>
import versionData from "./version.json";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      major: versionData.major,
      minor: versionData.minor,
      build: versionData.build,
    };
  },
  computed: {
    ...mapState(["isLoading"]), // Get loading state from Vuex
  },
  methods: {
    ...mapActions(["getExtraLakKleurList"]),
  },
  mounted: function () {
    console.log(
      "%c Version:" + this.major + "." + this.minor + "." + this.build,
      "background: #222; color: #bada55"
    );
    this.getExtraLakKleurList();
  },
};
</script>

<style lang="scss">
@import "./style/index.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: #333;
}
</style>
