<template>
  <div>
    <div v-if="$store.state.token == 'INVALID'">
      <BaseInfo :msg="$t('HelpRalkleur.Particulier.Info01')" />
      <BaseInfo :msg="$t('HelpRalkleur.Particulier.Info02')" />
      <BaseInfo :msg="$t('HelpRalkleur.Particulier.Info03')" />
      <BaseInfo :msg="$t('HelpRalkleur.Particulier.Info04')" />
    </div>
    <div v-if="$store.state.token != 'INVALID'">
      <BaseInfo :msg="$t('HelpRalkleur.Dealer.Info01')" />
      <BaseInfo :msg="$t('HelpRalkleur.Dealer.Info02')" />
      <BaseInfo :msg="$t('HelpRalkleur.Dealer.Info03')" />
      <BaseInfo :msg="$t('HelpRalkleur.Dealer.Info04')" />
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpRalkleur",
};
</script>
