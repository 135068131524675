<template>
  <div>
    <div class="row align-items-center">
      <div class="col-6">
        <img src="@/assets/NEXPositie3scharnieren.png" class="" />
      </div>
      <div class="col-6">
        <img src="@/assets/NEXPositie4scharnieren.png" class="" />
      </div>
      <div class="col-12 pe-5" v-if="$store.state.token == 'INVALID'">
        <BaseInfo :msg="$t('HelpPositieScharnieren.Particulier.Info01')" />
        <BaseInfo :msg="$t('HelpPositieScharnieren.Particulier.Info02')" />
        <BaseInfo :msg="$t('HelpPositieScharnieren.Particulier.Info03')" />
        <BaseInfo :msg="$t('HelpPositieScharnieren.Particulier.Info04')" />
        <BaseInfo :msg="$t('HelpPositieScharnieren.Particulier.Info05')" />
      </div>
      <div class="col-12 pe-5" v-if="$store.state.token != 'INVALID'">
        <BaseInfo :msg="$t('HelpPositieScharnieren.Dealer.Info01')" />
        <BaseInfo :msg="$t('HelpPositieScharnieren.Dealer.Info02')" />
        <BaseInfo :msg="$t('HelpPositieScharnieren.Dealer.Info03')" />
        <BaseInfo :msg="$t('HelpPositieScharnieren.Dealer.Info04')" />
        <BaseInfo :msg="$t('HelpPositieScharnieren.Dealer.Info05')" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpPositieScharnieren",
};
</script>
