<template>
  <div class="home">
    <form id="alustaForm">
      <div class="row p-2 m-2">
        <div class="col-xl-8">
          <div class="hideOnScreen p-5 row">
            <div class="col-2"><LogoScreen /></div>
          </div>
          <DrawScreen />
        </div>
        <div class="col-xl-4 text-start m-0 p-0 hideOnPrint">
          <div class="m-5 text-center">
            <LocalChange />
            <LogoScreen />
          </div>
          <div class="m-0 p-0 pb-3">
            <div>
              <BigButton
                :src="require('@/assets/AlustaFLEXLogo.png')"
                :selected="this.$store.state.productAssortiment == 0"
                v-on:click.prevent="selectProductAssortiment(0)"
              />
              <BigButton
                :src="require('@/assets/AlustaNEXLogo.png')"
                :selected="this.$store.state.productAssortiment == 1"
                v-on:click.prevent="selectProductAssortiment(1)"
              />
            </div>
          </div>
          <!-- DEURKADERS-->
          <div
            v-if="this.productAssortiment == 1"
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
          >
            <MainTitle msg="MainTitle.Deurkaders" />

            <SubTitle
              msg="MainTitle.Deurkaders"
              :showQuestion="true"
              helpProp="deurkader"
            />
            <HelpDeurkader v-if="$store.state.helpProperty.deurkader" />
            <div class="mt-5">
              <BigButton
                :src="require('@/assets/NEXDeurkader.png')"
                msg="Deurkader.Nex"
                :selected="this.$store.state.deurkaderKeuze == 0"
                v-on:click.prevent="setDeurkaderKeuze(0)"
              />
              <BigButton
                :src="require('@/assets/GPDeurkader.png')"
                msg="Deurkader.GP"
                :selected="this.$store.state.deurkaderKeuze == 1"
                v-on:click.prevent="setDeurkaderKeuze(1)"
              />
              <BigButton
                :src="require('@/assets/ZonderDeurkader.png')"
                msg="Deurkader.Zonder"
                :selected="this.$store.state.deurkaderKeuze == 2"
                v-on:click.prevent="setDeurkaderKeuze(2)"
              />
            </div>
          </div>
          <!-- END DEURKADERS-->
          <!-- ScharnierType-->
          <div
            v-if="this.productAssortiment == 1 && this.deurkaderKeuze == 2"
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
          >
            <MainTitle msg="MainTitle.ScharnierType" />
            <SubTitle
              msg="MainTitle.ScharnierType"
              :showQuestion="true"
              helpProp="scharnieren"
            />
            <HelpScharnieren v-if="$store.state.helpProperty.scharnieren" />
            <div class="mt-5">
              <BigButton
                :src="require('@/assets/NEX05.png')"
                msg="ScharnierType.Nex05"
                :selected="this.$store.state.scharnierType == 0"
                v-on:click.prevent="setScharnierType(0)"
              />
              <BigButton
                :src="require('@/assets/DF05.png')"
                msg="ScharnierType.DF05"
                :selected="this.$store.state.scharnierType == 1"
                v-on:click.prevent="setScharnierType(1)"
              />
            </div>
          </div>
          <!-- END ScharnierType-->
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
          >
            <MainTitle msg="MainTitle.Deuren/panelen" />
            <div v-if="this.productAssortiment == 0">
              <SubTitle msg="SubTitle.Producttype" />
              <BigButton
                :src="require('@/assets/EnkeleHydraulischeDeur.png')"
                msg="Producttype.Hydraulische"
                :selected="this.$store.state.productType == 1"
                v-on:click.prevent="selectProductType(1, 1)"
              />
              <BigButton
                :src="require('@/assets/EnkelVastPaneel.png')"
                msg="Producttype.Paneel"
                :selected="this.$store.state.productType == 0"
                v-on:click.prevent="selectProductType(0, 0)"
              />
              <BigButton
                :src="require('@/assets/Schuifdeur.png')"
                msg="Producttype.Sliding"
                :selected="this.$store.state.productType == 2"
                v-on:click.prevent="selectProductType(2, 1)"
                v-if="Token.substring(0, 3) === 'EGD'"
              />
              <BaseInfo :msg="$t('Producttype.Info01')" />
            </div>
            <div
              v-if="
                this.$store.state.aantalDeuren != 0 &&
                this.productAssortiment == 0
              "
            >
              <SubTitle msg="SubTitle.Deurtype" />
              <div v-if="this.$store.state.productType == 1">
                <BigButton
                  :src="require('@/assets/EnkeleDeur.png')"
                  msg="Deurtype.Enkel"
                  :selected="this.$store.state.aantalDeuren == 1"
                  v-on:click.prevent="selectAantalDeuren(1)"
                />
                <BigButton
                  :src="require('@/assets/DubbeleDeur.png')"
                  msg="Deurtype.Dubbel"
                  :selected="this.$store.state.aantalDeuren == 2"
                  v-on:click.prevent="selectAantalDeuren(2)"
                />
              </div>
              <div v-if="this.$store.state.productType == 2">
                <BigButton
                  :src="require('@/assets/EnkeleSchuifdeur.png')"
                  msg="Deurtype.Enkel"
                  :selected="this.$store.state.aantalDeuren == 1"
                  v-on:click.prevent="selectAantalDeuren(1)"
                />
                <BigButton
                  :src="require('@/assets/DubbeleSchuifdeur.png')"
                  msg="Deurtype.Dubbel"
                  :selected="this.$store.state.aantalDeuren == 2"
                  v-on:click.prevent="selectAantalDeuren(2)"
                />
              </div>
            </div>

            <div
              v-if="(productType == 1 || productType == 2) && aantalDeuren == 1"
            >
              <div v-if="this.$store.state.productType == 1">
                <SubTitle msg="SubTitle.Draairichting" />
                <BigButton
                  :src="require('@/assets/DraairichtingLinks.png')"
                  msg="Base.Links"
                  :selected="this.$store.state.draairichting == 0"
                  v-on:click.prevent="selectDraairichting(0)"
                />
                <BigButton
                  :src="require('@/assets/DraairichtingRechts.png')"
                  msg="Base.Rechts"
                  :selected="this.$store.state.draairichting == 1"
                  v-on:click.prevent="selectDraairichting(1)"
                />
              </div>
              <div v-if="this.$store.state.productType == 2">
                <SubTitle msg="SubTitle.Openingsrichting" />
                <BigButton
                  :src="require('@/assets/EnkeleSchuifdeur_L.png')"
                  msg="Base.Links"
                  :selected="this.$store.state.draairichting == 0"
                  v-on:click.prevent="selectDraairichting(0)"
                />
                <BigButton
                  :src="require('@/assets/EnkeleSchuifdeur_R.png')"
                  msg="Base.Rechts"
                  :selected="this.$store.state.draairichting == 1"
                  v-on:click.prevent="selectDraairichting(1)"
                />
              </div>
            </div>
            <!-- Deurkader maten-->
            <div
              v-if="this.productAssortiment == 1 && this.deurkaderKeuze != 2"
            >
              <SubTitle msg="SubTitle.Deurkadermaten" />
              <div class="m-0 p-0 row g-0 align-items-end mb-3">
                <div class="col-6">
                  <div class="m-0 p-0 row g-0 align-items-end">
                    <div class="col-4">
                      <img
                        src="@/assets/DeurkaderbreedteNEX.png"
                        style="height: 150px"
                        v-if="this.deurkaderKeuze == 0"
                      />
                      <img
                        src="@/assets/DeurkaderbreedteGP.png"
                        style="height: 150px"
                        v-if="this.deurkaderKeuze == 1"
                      />
                    </div>
                    <div class="col-8">
                      <h3 class="mb-3">{{ $t("Deurkader.Breedte") }}</h3>
                      <div class="m-0 p-0 row g-0 align-items-center">
                        <div class="col-9">
                          <input
                            type="number"
                            id="Bkader"
                            class="form-control rounded-pill"
                            v-model="Bdeurkader"
                            :class="[
                              $store.getters.isMaxBreedteOK &&
                              $store.getters.isMinBreedteOK &&
                              $store.getters.isGewichtOK
                                ? ''
                                : 'is-invalid',
                            ]"
                          />
                        </div>
                        <div class="col-3">mm</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="m-0 p-0 row g-0 align-items-end">
                    <div class="col-4 p-0 m-0">
                      <img
                        src="@/assets/DeurkaderhoogteNEX.png"
                        style="height: 150px"
                        v-if="this.deurkaderKeuze == 0"
                      />
                      <img
                        src="@/assets/DeurkaderhoogteGP.png"
                        style="height: 150px"
                        v-if="this.deurkaderKeuze == 1"
                      />
                    </div>
                    <div class="col-8">
                      <h3 class="mb-3">{{ $t("Deurkader.Hoogte") }}</h3>
                      <div class="m-0 p-0 row g-0 align-items-center">
                        <div class="col-9">
                          <input
                            type="number"
                            id="Hkader"
                            class="form-control rounded-pill"
                            v-model="Hdeurkader"
                            :class="[
                              $store.getters.isMaxHoogteOK &&
                              $store.getters.isMinHoogteOK &&
                              $store.getters.isGewichtOK
                                ? ''
                                : 'is-invalid',
                            ]"
                          />
                        </div>
                        <div class="col-3">mm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BaseInfo
                :msg="
                  $t('Deurkader.Info01', [
                    this.$store.state.spanmaat.Breedte,
                    this.$store.state.spanmaat.Hoogte,
                  ])
                "
              />
              <BaseInfo
                :msg="
                  $t('Deurkader.Info02', [
                    this.$store.state.kadermaat.Breedte,
                    this.$store.state.kadermaat.Hoogte,
                  ])
                "
              />
              <NexSpeling />
            </div>
            <!-- End Deurkader maten-->
            <!-- DEURMATEN -->
            <div
              v-if="
                this.productAssortiment == 0 ||
                (this.productAssortiment == 1 && this.deurkaderKeuze == 2)
              "
            >
              <SubTitle
                msg="SubTitle.Deurmaten"
                :showQuestion="this.productAssortiment == 0 ? true : false"
                helpProp="deurmaten"
              />
              <HelpDeurmaten v-if="$store.state.helpProperty.deurmaten" />
              <div class="m-0 p-0 row g-0 align-items-end mb-3">
                <div class="col-6">
                  <div class="m-0 p-0 row g-0 align-items-end">
                    <div class="col-4">
                      <img
                        src="@/assets/Deurbreedte.png"
                        style="height: 150px"
                      />
                    </div>
                    <div class="col-8">
                      <h3 class="mb-3">{{ $t("Deurmaten.Breedte") }}</h3>
                      <div class="m-0 p-0 row g-0 align-items-center">
                        <div class="col-9">
                          <input
                            type="number"
                            id="Bkader"
                            class="form-control rounded-pill"
                            v-model="Bkader"
                            :class="[
                              $store.getters.isMaxBreedteOK &&
                              $store.getters.isMinBreedteOK &&
                              $store.getters.isGewichtOK
                                ? ''
                                : 'is-invalid',
                            ]"
                          />
                        </div>
                        <div class="col-3">mm</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="m-0 p-0 row g-0 align-items-end">
                    <div class="col-4 p-0 m-0">
                      <img
                        src="@/assets/Deurhoogte.png"
                        style="height: 150px"
                      />
                    </div>
                    <div class="col-8">
                      <h3 class="mb-3">{{ $t("Deurmaten.Hoogte") }}</h3>
                      <div class="m-0 p-0 row g-0 align-items-center">
                        <div class="col-9">
                          <input
                            type="number"
                            id="Hkader"
                            class="form-control rounded-pill"
                            v-model="Hkader"
                            :class="[
                              $store.getters.isMaxHoogteOK &&
                              $store.getters.isMinHoogteOK &&
                              $store.getters.isGewichtOK
                                ? ''
                                : 'is-invalid',
                            ]"
                          />
                        </div>
                        <div class="col-3">mm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <BaseInfo
                :msg="
                  $t(
                    this.$store.state.productAssortiment == 0
                      ? 'Deurmaten.Info01'
                      : 'Deurmaten.Info02',
                    [
                      this.$store.state.spanmaat.Breedte,
                      this.$store.state.spanmaat.Hoogte,
                    ]
                  )
                "
              />
            </div>
            <!-- END DEURMATEN -->
            <!-- INFO EN ERROR DEURMATEN EN KADER -->
            <div>
              <BaseInfo
                v-if="this.debug"
                :msg="
                  $t('Debug.afmeting', [
                    this.$store.state.glasmaat.Breedte,
                    this.$store.state.glasmaat.Hoogte,
                  ])
                "
              />
              <BaseInfo
                :msg="
                  $t('Deurmaten.Error01', [
                    this.$store.getters.getMaxHoogteInfo,
                  ])
                "
                :error="true"
                v-if="!$store.getters.isMaxHoogteOK"
              />
              <BaseInfo
                :msg="
                  $t('Deurmaten.Error02', [
                    this.$store.getters.getMaxBreedteInfo,
                  ])
                "
                :error="true"
                v-if="!$store.getters.isMaxBreedteOK"
              />
              <BaseInfo
                :msg="$t('Deurmaten.Error03')"
                :error="true"
                v-if="!$store.getters.isGewichtOK"
              />
            </div>
            <!-- END INFO EN ERROR DEURMATEN EN KADER -->
            <AlTools v-if="this.productAssortiment == 0" />
            <!-- Zijspeling -->
            <div v-if="productType == 2">
              <SubTitle msg="SubTitle.Zijspeling" />
              <div class="mx-3 mb-3">
                <label for="zijspeling">{{ $t("Zijspeling.Afstand") }}</label>
                <input
                  type="number"
                  id="zijspeling"
                  class="form-control rounded-pill"
                  v-model="schuifdeurZijspeling"
                />
              </div>
            </div>
            <!-- END Zijspeling -->
            <!-- Scharnieren -->
            <div v-if="this.productAssortiment == 1">
              <SubTitle
                msg="SubTitle.Scharnieren"
                showQuestion="true"
                helpProp="positiescharnieren"
              />
              <HelpPositieScharnieren
                v-if="$store.state.helpProperty.positiescharnieren"
              />
              <div class="row g-0 mx-3 mb-3 mt-3">
                <div class="col-6">
                  <div class="mx-3 mb-3">
                    <NumButton
                      :msg="$t('Scharnieren.Standaard')"
                      :selected="this.$store.state.scharnierenPositieType == 0"
                      v-on:click.prevent="setScharnierenPositieType(0)"
                    />
                    <NumButton
                      :msg="$t('Scharnieren.OpMaat')"
                      :selected="this.$store.state.scharnierenPositieType == 1"
                      v-on:click.prevent="setScharnierenPositieType(1)"
                    />
                  </div>
                  <div
                    class="m-0 p-0 row g-0 align-items-center"
                    v-if="this.$store.state.scharnierenPositieType == 1"
                  >
                    <div class="col-2">V1</div>
                    <div class="col-8">
                      <input
                        type="number"
                        class="form-control rounded-pill"
                        :value="$store.state.scharnieren[0]"
                        v-on:input="updateScharnieren($event, 0)"
                      />
                    </div>
                    <div class="col-2">mm</div>
                    <div class="col-2">V2</div>
                    <div class="col-8">
                      <input
                        type="number"
                        class="form-control rounded-pill"
                        :value="$store.state.scharnieren[1]"
                        v-on:input="updateScharnieren($event, 1)"
                      />
                    </div>
                    <div class="col-2">mm</div>
                    <div class="col-2">V3</div>
                    <div class="col-8">
                      <input
                        type="number"
                        class="form-control rounded-pill"
                        :value="$store.state.scharnieren[2]"
                        v-on:input="updateScharnieren($event, 2)"
                      />
                    </div>
                    <div class="col-2">mm</div>
                    <div class="col-2" v-if="Hkader > 2400">V4</div>
                    <div class="col-8" v-if="Hkader > 2400">
                      <input
                        type="number"
                        class="form-control rounded-pill"
                        :value="$store.state.scharnieren[3]"
                        v-on:input="updateScharnieren($event, 3)"
                      />
                    </div>
                    <div class="col-2" v-if="Hkader > 2400">mm</div>
                  </div>
                </div>
                <div class="col-6">
                  <img
                    v-if="Hkader <= 2400"
                    src="@/assets/NEXPositie3scharnieren.png"
                    style="height: 250px"
                  />
                  <img
                    v-if="Hkader > 2400"
                    src="@/assets/NEXPositie4scharnieren.png"
                    style="height: 250px"
                  />
                </div>
              </div>
            </div>
            <!-- END Scharnieren -->
            <!-- PANELEN -->
            <div v-if="this.productAssortiment == 0">
              <SubTitle msg="SubTitle.Panelen" />
              <div class="row g-0 mx-3 mb-3">
                <div class="col-6 p-3 border-end">
                  <img src="@/assets/PanelenLinks.png" style="height: 150px" />
                  <strong>{{ $t("Base.Links") }}</strong>
                  <div class="text-center mb-3">
                    <NumButton
                      msg="0"
                      :selected="this.$store.state.aantalPanelenLinks == 0"
                      v-on:click.prevent="setAantalPanelenLinks(0)"
                    />
                    <NumButton
                      msg="1"
                      :selected="this.$store.state.aantalPanelenLinks == 1"
                      v-on:click.prevent="setAantalPanelenLinks(1)"
                    />
                    <NumButton
                      msg="2"
                      :selected="this.$store.state.aantalPanelenLinks == 2"
                      v-on:click.prevent="setAantalPanelenLinks(2)"
                    />
                    <NumButton
                      msg="3"
                      :selected="this.$store.state.aantalPanelenLinks == 3"
                      v-on:click.prevent="setAantalPanelenLinks(3)"
                    />
                  </div>
                  <div
                    class="mb-3"
                    v-for="(item, index) in $store.state.panelenLinks"
                    :key="index"
                  >
                    <label>{{ $t("Panelen.PaneelNum", [index + 1]) }}</label>
                    <input
                      type="number"
                      class="form-control rounded-pill"
                      :value="$store.state.panelenLinks[index]"
                      v-on:input="updatePaneelLinks($event, index)"
                      :class="[
                        $store.state.panelenLinks[index] > 1500
                          ? 'is-invalid'
                          : '',
                      ]"
                    />
                    <div class="invalid-feedback">
                      {{ $t("Panelen.Error01") }}
                    </div>
                  </div>
                </div>
                <div class="col-6 p-3">
                  <img src="@/assets/PanelenRechts.png" style="height: 150px" />
                  <strong>{{ $t("Base.Rechts") }}</strong>
                  <div class="text-center mb-3">
                    <NumButton
                      msg="0"
                      :selected="this.$store.state.aantalPanelenRechts == 0"
                      v-on:click.prevent="setAantalPanelenRechts(0)"
                    />
                    <NumButton
                      msg="1"
                      :selected="this.$store.state.aantalPanelenRechts == 1"
                      v-on:click.prevent="setAantalPanelenRechts(1)"
                    />
                    <NumButton
                      msg="2"
                      :selected="this.$store.state.aantalPanelenRechts == 2"
                      v-on:click.prevent="setAantalPanelenRechts(2)"
                    />
                    <NumButton
                      msg="3"
                      :selected="this.$store.state.aantalPanelenRechts == 3"
                      v-on:click.prevent="setAantalPanelenRechts(3)"
                    />
                  </div>

                  <div
                    class="mb-3"
                    v-for="(item, index) in $store.state.panelenRechts"
                    :key="index"
                  >
                    <label>{{ $t("Panelen.PaneelNum", [index + 1]) }}</label>
                    <input
                      type="number"
                      class="form-control rounded-pill"
                      :value="$store.state.panelenRechts[index]"
                      v-on:input="updatePaneelRechts($event, index)"
                      :class="[
                        $store.state.panelenRechts[index] > 1500
                          ? 'is-invalid'
                          : '',
                      ]"
                    />
                    <div class="invalid-feedback">
                      {{ $t("Panelen.Error01") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END PANELEN -->

            <!-- Draaipunt -->
            <div v-if="productType == 1 && this.productAssortiment == 0">
              <SubTitle
                msg="SubTitle.Draaipunt"
                :showQuestion="true"
                helpProp="draaipunt"
              />
              <HelpDraaipunt v-if="$store.state.helpProperty.draaipunt" />
              <div class="mx-3 mb-3">
                <label for="draaipuntX">{{ $t("Draaipunt.Afstand") }}</label>
                <input
                  type="number"
                  id="draaipuntX"
                  class="form-control rounded-pill"
                  v-model="draaipuntX"
                  :class="[
                    draaipuntX > Bkader / 2 || draaipuntX < 110
                      ? 'is-invalid'
                      : '',
                  ]"
                />
              </div>
              <BaseInfo :msg="$t('Draaipunt.Info01')" />
              <BaseInfo
                :msg="$t('Draaipunt.Error01')"
                :error="true"
                v-if="this.$store.state.toonDraaipuntWaarschuwing"
              />
              <BaseInfo
                :msg="$t('Draaipunt.Error02', [Bkader / 2])"
                :error="true"
                v-if="draaipuntX > Bkader / 2"
              />
              <BaseInfo
                :msg="$t('Draaipunt.Error03')"
                :error="true"
                v-if="draaipuntX < 110"
              />
            </div>

            <!-- END Draaipunt -->
          </div>
          <!-- END DEUREN / PANELEN -->

          <!-- KADERVERDELING //-->
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
          >
            <MainTitle msg="MainTitle.Kaderverdeling" />
            <SubTitle
              msg="SubTitle.Aantal/positie"
              :showQuestion="true"
              helpProp="onderverdeling"
            />
            <HelpOnderverdeling
              v-if="$store.state.helpProperty.onderverdeling"
            />
            <div class="row g-0 mx-3 mb-3">
              <div class="col-6 p-3 border-end">
                <img src="@/assets/Verticaal.png" style="height: 150px" />
                <strong>{{ $t("Base.Verticaal") }}</strong>
                <div class="mb-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="asymmetrisch"
                    />
                    <label class="form-check-label">
                      {{ $t("Panelen.Asymmetrisch") }}
                    </label>
                  </div>
                </div>
                <div class="text-center mb-3">
                  <NumButton
                    msg="0"
                    :selected="this.verticaleOnderverdeling == 0"
                    v-on:click.prevent="setAantalVerticaleOnderverdeling(0)"
                  />
                  <NumButton
                    msg="1"
                    :selected="this.verticaleOnderverdeling == 1"
                    v-on:click.prevent="setAantalVerticaleOnderverdeling(1)"
                  />
                  <NumButton
                    msg="2"
                    :selected="this.verticaleOnderverdeling == 2"
                    v-on:click.prevent="setAantalVerticaleOnderverdeling(2)"
                  />
                </div>
                <!-- dividers aanpassen -->
                <div v-if="asymmetrisch">
                  <div
                    class="mb-3"
                    v-for="(item, index) in $store.state
                      .verticaleOnderverdelingen"
                    :key="index"
                  >
                    <label>
                      {{ $t("Base.Verticaal") }} {{ index + 1 }} (mm)</label
                    >
                    <input
                      type="number"
                      class="form-control rounded-pill"
                      :value="$store.state.verticaleOnderverdelingen[index]"
                      v-on:input="updateVerticaleOnderverdeling($event, index)"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 p-3">
                <img src="@/assets/Horizontaal.png" style="height: 150px" />
                <strong>{{ $t("Base.Horizontaal") }}</strong>
                <div class="mb-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="asymmetrisch"
                    />
                    <label class="form-check-label">
                      {{ $t("Panelen.Asymmetrisch") }}
                    </label>
                  </div>
                </div>
                <div class="text-center mb-3">
                  <NumButton
                    msg="0"
                    :selected="this.horizontaleOnderverdeling == 0"
                    v-on:click.prevent="setAantalHorizontaleOnderverdeling(0)"
                  />
                  <NumButton
                    msg="1"
                    :selected="this.horizontaleOnderverdeling == 1"
                    v-on:click.prevent="setAantalHorizontaleOnderverdeling(1)"
                  />
                  <NumButton
                    msg="2"
                    :selected="this.horizontaleOnderverdeling == 2"
                    v-on:click.prevent="setAantalHorizontaleOnderverdeling(2)"
                  />
                  <NumButton
                    msg="3"
                    :selected="this.horizontaleOnderverdeling == 3"
                    v-on:click.prevent="setAantalHorizontaleOnderverdeling(3)"
                  />
                </div>
                <!-- dividers aanpassen -->
                <div v-if="asymmetrisch">
                  <div
                    class="mb-3"
                    v-for="(item, index) in $store.state
                      .horizontaleOnderverdelingen"
                    :key="index"
                  >
                    <label>
                      {{ $t("Base.Horizontaal") }} {{ index + 1 }} (mm)</label
                    >
                    <input
                      type="number"
                      class="form-control rounded-pill"
                      :value="$store.state.horizontaleOnderverdelingen[index]"
                      v-on:input="
                        updateHorizontaleOnderverdeling($event, index)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                this.productAssortiment == 0 &&
                (horizontaleOnderverdeling > 0 || verticaleOnderverdeling > 0)
              "
            >
              <SubTitle msg="SubTitle.ALOType" />
              <BigButton
                :src="require('@/assets/TProfiel.png')"
                msg="Panelen.TProfiel"
                :selected="this.$store.state.ALOkeuze == 0"
                v-on:click.prevent="setALOkeuze(0)"
              />
              <BigButton
                :src="require('@/assets/Profiel.png')"
                msg="Panelen.IProfiel"
                :selected="this.$store.state.ALOkeuze == 1"
                v-on:click.prevent="setALOkeuze(1)"
              />

              <BaseInfo :msg="$t('Panelen.ProfielInfo')" />
            </div>
          </div>
          <!-- END KADERVERDELING //-->
          <!-- DEURGREPEN //-->
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
            v-if="aantalDeuren > 0"
          >
            <MainTitle msg="MainTitle.Deurgrepen" />
            <div>
              <SubTitle
                v-if="this.productAssortiment == 0"
                msg="SubTitle.Greeptype"
              />
              <SubTitle
                v-if="this.productAssortiment == 1"
                msg="SubTitle.Greeptype"
                :showQuestion="true"
                helpProp="greeptype"
              />
              <HelpGreeptype v-if="$store.state.helpProperty.greeptype" />
              <BigButton
                :src="require('@/assets/Hoekgreep.png')"
                msg="Greeptype.Hoekgreep"
                :selected="
                  this.$store.state.hendelKeuze == 0 ||
                  (this.$store.state.hendelKeuze >= 9 &&
                    this.$store.state.hendelKeuze <= 14)
                "
                v-on:click.prevent="setHendelKeuze(0)"
                v-if="this.productAssortiment == 0"
              />
              <BigButton
                :src="require('@/assets/NEXHG.png')"
                msg="Greeptype.NEXHG"
                :selected="
                  this.$store.state.hendelKeuze >= 15 &&
                  this.$store.state.hendelKeuze <= 20
                "
                v-on:click.prevent="setHendelKeuze(16)"
                v-if="this.productAssortiment == 1"
              />
              <BigButton
                :src="require('@/assets/NEXEG.png')"
                msg="Greeptype.NEXEG"
                :selected="
                  this.$store.state.hendelKeuze >= 21 &&
                  this.$store.state.hendelKeuze <= 26
                "
                v-on:click.prevent="setHendelKeuze(22)"
                v-if="this.productAssortiment == 1"
              />
              <BigButton
                :src="require('@/assets/NXCS01.png')"
                msg="Greeptype.NXCS01"
                :selected="this.$store.state.hendelKeuze == 27"
                v-on:click.prevent="setHendelKeuze(27)"
                v-if="this.productAssortiment == 1 && false"
              />
              <BigButton
                :src="require('@/assets/AMURSQTrekker.png')"
                msg="Greeptype.AMURSQ"
                :selected="
                  (this.$store.state.hendelKeuze >= 5 &&
                    this.$store.state.hendelKeuze <= 8) ||
                  (this.$store.state.hendelKeuze >= 28 &&
                    this.$store.state.hendelKeuze <= 29)
                "
                v-on:click.prevent="setHendelKeuze(5)"
              />
              <BigButton
                :src="require('@/assets/AMTTrekker.png')"
                msg="Greeptype.AMT"
                :selected="
                  this.$store.state.hendelKeuze >= 1 &&
                  this.$store.state.hendelKeuze <= 4
                "
                v-on:click.prevent="setHendelKeuze(1)"
              />
            </div>
            <!-- HOEKGREPEN -->
            <div
              v-if="hendelKeuze == 0 || (hendelKeuze >= 9 && hendelKeuze <= 14)"
            >
              <SubTitle msg="SubTitle.Maten" />
              <div class="row align-items-center">
                <div class="col-6 text-center">
                  <img
                    src="@/assets/HoekgreepMaten.png"
                    style="height: 250px"
                  />
                </div>
                <div class="col-6">
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=150, b=35.5"
                      :selected="this.hendelKeuze == 0"
                      v-on:click.prevent="setHendelKeuze(0)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=300, b=35.5"
                      :selected="this.hendelKeuze == 9"
                      v-on:click.prevent="setHendelKeuze(9)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=500, b=35.5"
                      :selected="this.hendelKeuze == 10"
                      v-on:click.prevent="setHendelKeuze(10)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=1200, b=35.5"
                      :selected="this.hendelKeuze == 11"
                      v-on:click.prevent="setHendelKeuze(11)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=2000, b=35.5"
                      :selected="this.hendelKeuze == 12"
                      v-on:click.prevent="setHendelKeuze(12)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=25, a=100, l=?, b=35.5"
                      :selected="this.hendelKeuze == 13"
                      v-on:click.prevent="setHendelKeuze(13)"
                    />
                  </p>
                  <div v-if="hendelKeuze == 13">
                    <label for="greeplengte">{{
                      $t("Base.greeplengte")
                    }}</label>
                    <input
                      type="number"
                      id="greeplengte"
                      class="form-control rounded-pill"
                      v-model="greeplengte"
                      :class="[greeplengte < 10 ? 'is-invalid' : '']"
                    />
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-6 text-center">
                  <img
                    src="@/assets/MiddenslotMetHoekgreepConfiguratorMaten-01.png"
                    style="height: 250px"
                  />
                </div>
                <div class="col-6">
                  <h2 class="font-egdl fw-bold fs-6">
                    {{ $t("SubTitle.MetMiddenslot") }}
                  </h2>
                  <p>
                    <NumButton
                      msg="d=30, a=70, l=100, b=35.5"
                      :selected="this.hendelKeuze == 14"
                      v-on:click.prevent="setHendelKeuze(14)"
                    />
                  </p>
                </div>
              </div>
            </div>
            <!-- NEXHG -->
            <div v-if="hendelKeuze >= 15 && hendelKeuze <= 20">
              <SubTitle msg="SubTitle.Maten" />
              <div class="row align-items-center">
                <div class="col-6 text-center">
                  <img src="@/assets/NEXHGmaat.png" style="height: 250px" />
                </div>
                <div class="col-6">
                  <!--<p>
                    <NumButton
                      msg="d=2, a=30, l=150, b=25"
                      :selected="this.hendelKeuze == 15"
                      v-on:click.prevent="setHendelKeuze(15)"
                    />
                  </p>//-->
                  <p>
                    <NumButton
                      msg="d=2, a=30, l=300, b=25"
                      :selected="this.hendelKeuze == 16"
                      v-on:click.prevent="setHendelKeuze(16)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=2, a=30, l=500, b=25"
                      :selected="this.hendelKeuze == 17"
                      v-on:click.prevent="setHendelKeuze(17)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=2, a=30, l=1200, b=25"
                      :selected="this.hendelKeuze == 18"
                      v-on:click.prevent="setHendelKeuze(18)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=2, a=30, l=2000, b=25"
                      :selected="this.hendelKeuze == 19"
                      v-on:click.prevent="setHendelKeuze(19)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=2, a=30, l=?, b=25"
                      :selected="this.hendelKeuze == 20"
                      v-on:click.prevent="setHendelKeuze(20)"
                    />
                  </p>
                  <div v-if="hendelKeuze == 20">
                    <label for="greeplengte">{{
                      $t("Base.greeplengte")
                    }}</label>
                    <input
                      type="number"
                      id="greeplengte"
                      class="form-control rounded-pill"
                      v-model="greeplengte"
                      :class="[greeplengte < 10 ? 'is-invalid' : '']"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- NEXEG -->
            <div v-if="hendelKeuze >= 21 && hendelKeuze <= 26">
              <SubTitle msg="SubTitle.Maten" />
              <div class="row align-items-center">
                <div class="col-6 text-center">
                  <img src="@/assets/NEXEGmaat.png" style="height: 250px" />
                </div>
                <div class="col-6">
                  <!--<p>
                    <NumButton
                      msg="d=2, a=50, l=150, b=25"
                      :selected="this.hendelKeuze == 21"
                      v-on:click.prevent="setHendelKeuze(21)"
                    />
                  </p>//-->
                  <p>
                    <NumButton
                      msg="d=2, a=50, l=300, b=25"
                      :selected="this.hendelKeuze == 22"
                      v-on:click.prevent="setHendelKeuze(22)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=2, a=50, l=500, b=25"
                      :selected="this.hendelKeuze == 23"
                      v-on:click.prevent="setHendelKeuze(23)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=2, a=50, l=1200, b=25"
                      :selected="this.hendelKeuze == 24"
                      v-on:click.prevent="setHendelKeuze(24)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=2, a=50, l=2000, b=25"
                      :selected="this.hendelKeuze == 25"
                      v-on:click.prevent="setHendelKeuze(25)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=2, a=50, l=?, b=25"
                      :selected="this.hendelKeuze == 26"
                      v-on:click.prevent="setHendelKeuze(26)"
                    />
                  </p>
                  <div v-if="hendelKeuze == 26">
                    <label for="greeplengte">{{
                      $t("Base.greeplengte")
                    }}</label>
                    <input
                      type="number"
                      id="greeplengte"
                      class="form-control rounded-pill"
                      v-model="greeplengte"
                      :class="[greeplengte < 10 ? 'is-invalid' : '']"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- AMT -->
            <div v-if="hendelKeuze >= 1 && hendelKeuze <= 4">
              <SubTitle msg="SubTitle.Maten" />
              <div class="row align-items-center">
                <div class="col-6 text-center">
                  <img src="@/assets/AMTMaten.png" style="height: 250px" />
                </div>
                <div class="col-6">
                  <p>
                    <NumButton
                      msg="d=20, a=200, l=300"
                      :selected="this.hendelKeuze == 1"
                      v-on:click.prevent="setHendelKeuze(1)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=20, a=340, l=500"
                      :selected="this.hendelKeuze == 2"
                      v-on:click.prevent="setHendelKeuze(2)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=20, a=540, l=700"
                      :selected="this.hendelKeuze == 3"
                      v-on:click.prevent="setHendelKeuze(3)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="d=20, a=1040, l=1200"
                      :selected="this.hendelKeuze == 4"
                      v-on:click.prevent="setHendelKeuze(4)"
                    />
                  </p>
                </div>
              </div>
            </div>

            <!-- AMURSQ -->
            <div
              v-if="
                (hendelKeuze >= 5 && hendelKeuze <= 8) ||
                (hendelKeuze >= 28 && hendelKeuze <= 29)
              "
            >
              <SubTitle msg="SubTitle.Maten" />
              <div class="row align-items-center">
                <div class="col-6 text-center">
                  <img src="@/assets/AMURSQMaten.png" style="height: 250px" />
                </div>
                <div class="col-6">
                  <p>
                    <NumButton
                      msg="b=20, d=20, e=20, c=60, a=300, l=320"
                      :selected="this.hendelKeuze == 5"
                      v-on:click.prevent="setHendelKeuze(5)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="b=10, d=20, e=20, c=50, a=380, l=400"
                      :selected="this.hendelKeuze == 28"
                      v-on:click.prevent="setHendelKeuze(28)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="b=20, d=20, e=20, c=60, a=500, l=520"
                      :selected="this.hendelKeuze == 6"
                      v-on:click.prevent="setHendelKeuze(6)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="b=20, d=20, e=20, c=60, a=700, l=720"
                      :selected="this.hendelKeuze == 29"
                      v-on:click.prevent="setHendelKeuze(29)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="b=20, d=20, e=20, c=60, a=1200, l=1220"
                      :selected="this.hendelKeuze == 7"
                      v-on:click.prevent="setHendelKeuze(7)"
                    />
                  </p>
                  <p>
                    <NumButton
                      msg="b=30, d=30, e=30, c=60, a=2000, l=2030"
                      :selected="this.hendelKeuze == 8"
                      v-on:click.prevent="setHendelKeuze(8)"
                    />
                  </p>
                </div>
              </div>
              <BaseInfo
                :msg="$t('Greeptype.Error01')"
                :error="true"
                v-if="$store.state.hendel[hendelKeuze].p_height > Hkader"
              />
            </div>
            <SubTitle
              msg="SubTitle.Positie"
              :showQuestion="true"
              helpProp="deurgrepen"
            />
            <HelpDeurgrepen v-if="$store.state.helpProperty.deurgrepen" />
            <div class="row mx-3 mb-3">
              <div class="col-6">
                <label for="hendelPositieY">{{ $t("Base.Verticaal") }}</label>
                <input
                  type="number"
                  id="hendelPositieY"
                  class="form-control rounded-pill"
                  v-model="hendelPositieY"
                  :class="[
                    hendelPositieY <
                      $store.state.hendel[hendelKeuze].p_height / 2 ||
                    hendelPositieY >
                      $store.state.kadermaat.Hoogte -
                        $store.state.hendel[hendelKeuze].p_height / 2
                      ? 'is-invalid'
                      : '',
                  ]"
                />
              </div>
              <div
                class="col-6"
                v-if="
                  (hendelKeuze >= 1 && hendelKeuze <= 8) ||
                  (hendelKeuze >= 28 && hendelKeuze <= 29)
                "
              >
                <label for="hendelPositieX">{{ $t("Base.Horizontaal") }}</label>
                <input
                  type="number"
                  id="hendelPositieX"
                  class="form-control rounded-pill"
                  v-model="hendelPositieX"
                />
              </div>
            </div>
            <!-- Magneetsluiting -->
            <div
              v-if="
                this.productAssortiment == 1 &&
                $store.state.hendel[this.hendelKeuze].p_heeftMagneetsluiting ==
                  1
              "
            >
              <SubTitle
                msg="SubTitle.Magneetsluiting"
                :showQuestion="true"
                helpProp="magneetsluiting"
              />
              <HelpMagneetsluiting
                v-if="$store.state.helpProperty.magneetsluiting"
              />
              <div class="row mx-3 mb-3">
                <div class="col-6">
                  <label for="magneetPositieY">{{
                    $t("Base.Verticaal")
                  }}</label>
                  <input
                    type="number"
                    id="magneetPositieY"
                    class="form-control rounded-pill"
                    v-model="magneetPositieY"
                  />
                </div>
              </div>
            </div>

            <!--  SLOT -->
            <!-- Enkel tonen bij trekkers en FLEX.-->
            <div
              v-if="
                ((hendelKeuze >= 1 && hendelKeuze <= 8) ||
                  (hendelKeuze >= 28 && hendelKeuze <= 29)) &&
                this.productAssortiment == 0
              "
            >
              <SubTitle
                msg="SubTitle.MetMiddenslot"
                :showQuestion="true"
                helpProp="trekkermetslot"
              />
              <HelpTrekkerMetSlot
                v-if="$store.state.helpProperty.trekkermetslot"
              />
              <BigButton
                :src="require('@/assets/AS522.png')"
                msg="Slot.1Middenslot"
                :selected="this.middenslot == 1"
                v-on:click.prevent="setMiddenslot(1)"
              />
              <BigButton
                :src="require('@/assets/GeenSlot.png')"
                msg="Slot.0Middenslot"
                :selected="this.middenslot == 0"
                v-on:click.prevent="setMiddenslot(0)"
              />
            </div>
            <div v-if="this.middenslot == 1">
              <SubTitle msg="SubTitle.Slotplaat" />
              <BigButton
                :src="require('@/assets/V532.png')"
                msg="Slot.1Slotplaat"
                :selected="this.slotplaat == 1"
                v-on:click.prevent="setSlotplaat(1)"
              />
              <BigButton
                :src="require('@/assets/GeenSlot.png')"
                msg="Slot.0Slotplaat"
                :selected="this.slotplaat == 0"
                v-on:click.prevent="setSlotplaat(0)"
              />
              <!--Cilinders-->
              <SubTitle msg="SubTitle.Cilinders" />
              <BigButton
                :src="require('@/assets/V017.E.png')"
                msg="Slot.0CilinderKleur"
                :selected="this.cilinderkleur == 0"
                v-on:click.prevent="setCilinderKleur(0)"
              />
              <BigButton
                :src="require('@/assets/V017.G.png')"
                msg="Slot.1CilinderKleur"
                :selected="this.cilinderkleur == 1"
                v-on:click.prevent="setCilinderKleur(1)"
              />
              <br />
              <BigButton
                :src="require('@/assets/V017S.png')"
                msg="Slot.0CilinderType"
                :selected="this.cilindertype == 0"
                v-on:click.prevent="setCilinderType(0)"
              />
              <BigButton
                :src="require('@/assets/V017D.png')"
                msg="Slot.1CilinderType"
                :selected="this.cilindertype == 1"
                v-on:click.prevent="setCilinderType(1)"
              />
            </div>
            <!-- EINDE SLOT-->
          </div>
          <!-- END DEURGREPEN //-->
          <!-- GLAS //-->
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
          >
            <MainTitle msg="MainTitle.Glas" />
            <SubTitle msg="SubTitle.Glassoorten" />
            <BigButton
              v-for="model in this.$store.getters.getGlassoort"
              v-bind:key="model.originalIndex"
              :src="require('@/assets/Glassoorten/' + model.img)"
              :msg="model.title"
              :selected="
                this.$store.state.glassoortKeuze == model.originalIndex
              "
              v-on:click.prevent="setGlassoortKeuze(model.originalIndex)"
            />
            <BaseInfo :msg="$t('Error.glasstock')" :error="true" />
          </div>
          <!-- END GLAS //-->

          <!-- EXTRA //-->
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
            v-if="
              horizontaleOnderverdeling > 0 ||
              (aantalDeuren > 0 && this.productAssortiment == 0)
            "
          >
            <MainTitle msg="MainTitle.Extra" />
            <div v-if="horizontaleOnderverdeling > 0 && ALOkeuze != 1">
              <SubTitle msg="SubTitle.Opvulvlak" />
              <BigButton
                :src="require('@/assets/Opvulvlak.png')"
                msg="Opvulvlak.1Opvulvlak"
                :selected="this.opvulvlak == 1"
                v-on:click.prevent="setOpvulvlak(1)"
              />
              <BigButton
                :src="require('@/assets/GeenSlot.png')"
                msg="Opvulvlak.0Opvulvlak"
                :selected="this.opvulvlak == 0"
                v-on:click.prevent="setOpvulvlak(0)"
              />
              <BaseInfo :msg="$t('Opvulvlak.Info01')" />
            </div>
            <!--  SLOT -->
            <div v-if="aantalDeuren > 0 && this.productAssortiment == 0">
              <SubTitle msg="SubTitle.Deurslot" />
              <BigButton
                :src="require('@/assets/ASC01.png')"
                msg="Slot.1Slot"
                :selected="this.slot == 1"
                v-on:click.prevent="setSlot(1)"
              />
              <BigButton
                :src="require('@/assets/GeenSlot.png')"
                msg="Slot.0Slot"
                :selected="this.slot == 0"
                v-on:click.prevent="setSlot(0)"
              />
              <BaseInfo :msg="$t('Slot.Info01')" />
              <div v-if="this.slot == 1">
                <!-- SLOT POT-->
                <SubTitle msg="SubTitle.SlotPot" />
                <BigButton
                  :src="require('@/assets/V515.png')"
                  msg="Slot.1SlotPot"
                  :selected="this.slotpot == 1"
                  v-on:click.prevent="setSlotPot(1)"
                />
                <BigButton
                  :src="require('@/assets/GeenSlot.png')"
                  msg="Slot.0SlotPot"
                  :selected="this.slotpot == 0"
                  v-on:click.prevent="setSlotPot(0)"
                />
                <!--Cilinders-->
                <SubTitle msg="SubTitle.Cilinders" />
                <BigButton
                  :src="require('@/assets/V017.E.png')"
                  msg="Slot.0CilinderKleur"
                  :selected="this.cilinderkleur == 0"
                  v-on:click.prevent="setCilinderKleur(0)"
                />
                <BigButton
                  :src="require('@/assets/V017.G.png')"
                  msg="Slot.1CilinderKleur"
                  :selected="this.cilinderkleur == 1"
                  v-on:click.prevent="setCilinderKleur(1)"
                />
                <br />
                <BigButton
                  :src="require('@/assets/V017S.png')"
                  msg="Slot.0CilinderType"
                  :selected="this.cilindertype == 0"
                  v-on:click.prevent="setCilinderType(0)"
                />
                <BigButton
                  :src="require('@/assets/V017D.png')"
                  msg="Slot.1CilinderType"
                  :selected="this.cilindertype == 1"
                  v-on:click.prevent="setCilinderType(1)"
                />
              </div>
            </div>
            <!-- EINDE SLOT-->
            <!-- Kantschuifslot-->
            <div v-if="productType == 1 && this.productAssortiment == 0">
              <SubTitle msg="SubTitle.Kantschuifslot" />
              <BigButton
                :src="require('@/assets/Kantschuifmatzwart.png')"
                msg="Slot.1Kantschuifslot"
                :selected="this.kantschuifslot == 1"
                v-on:click.prevent="setKantschuifslot(1)"
              />
              <BigButton
                :src="require('@/assets/Kantschuifchrome.png')"
                msg="Slot.2Kantschuifslot"
                :selected="this.kantschuifslot == 2"
                v-on:click.prevent="setKantschuifslot(2)"
              />
              <BigButton
                :src="require('@/assets/GeenSlot.png')"
                msg="Slot.0Kantschuifslot"
                :selected="this.kantschuifslot == 0"
                v-on:click.prevent="setKantschuifslot(0)"
              />
            </div>
            <!-- EINDE Kantschuifslot-->
            <!--<div class="mx-3 mb-3" v-if="$store.state.slot == 1">
              <label>{{ $t("Slot.SlotX") }}</label>
              <input
                type="number"
                class="form-control rounded-pill"
                v-model="slotX"
              />
            </div>//-->
          </div>
          <!-- END EXTRA //-->
          <ColorSelect />
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
          >
            <MainTitle msg="Referenties.MainTitle" />
            <SubTitle msg="" />
            <div class="m-3" v-if="$store.state.orderID == 0">
              <label for="klantRef">{{ $t("Referenties.KlantRef") }}</label>
              <input
                id="klantRef"
                class="form-control rounded-pill"
                v-model="klantRef"
              />
            </div>
            <div class="m-3">
              <label for="referentie">{{ $t("Referenties.Referentie") }}</label>
              <input
                id="referentie"
                class="form-control rounded-pill"
                v-model="deurgeheelRef"
              />
            </div>
          </div>
          <PriceCalcScreen />
          <!-- QOUTE FORM //-->
          <div
            class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5"
            v-if="Token == 'INVALID'"
          >
            <MainTitle
              msg="OfferteFormulier.MainTitle"
              v-if="$store.getters.getCustomizationNumber != 4"
            />
            <MainTitle
              msg="OfferteFormulier.MainTitleVerdouw"
              v-if="$store.getters.getCustomizationNumber == 4"
            />
            <SubTitle
              msg="OfferteFormulier.SubTitle"
              v-if="$store.getters.getCustomizationNumber != 4"
            />
            <SubTitle
              msg=""
              v-if="$store.getters.getCustomizationNumber == 4"
            />
            <div v-if="qouteFormSubmitted">
              <BaseInfo :msg="$t('OfferteFormulier.bedankt')" />
            </div>
            <form
              id="qouteForm"
              @submit.prevent="sendMail"
              v-if="!qouteFormSubmitted"
            >
              <div class="m-3">
                <label for="qoute_firstname">{{
                  $t("OfferteFormulier.voornaam")
                }}</label>
                <input
                  id="qoute_firstname"
                  class="form-control rounded-pill"
                  v-model="qoute_firstname"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_lastname">{{
                  $t("OfferteFormulier.naam")
                }}</label>
                <input
                  id="qoute_lastname"
                  class="form-control rounded-pill"
                  v-model="qoute_lastname"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_phone">{{
                  $t("OfferteFormulier.tel")
                }}</label>
                <input
                  type="tel"
                  id="qoute_phone"
                  class="form-control rounded-pill"
                  v-model="qoute_phone"
                  pattern="(\+){0,1}[0-9\s]{5,}"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_mail">{{
                  $t("OfferteFormulier.email")
                }}</label>
                <input
                  type="email"
                  id="qoute_mail"
                  class="form-control rounded-pill"
                  v-model="qoute_mail"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_street">{{
                  $t("OfferteFormulier.straat")
                }}</label>
                <input
                  id="qoute_street"
                  class="form-control rounded-pill"
                  v-model="qoute_street"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_zip">{{
                  $t("OfferteFormulier.postcode")
                }}</label>
                <input
                  id="qoute_zip"
                  class="form-control rounded-pill"
                  v-model="qoute_zip"
                  required
                />
              </div>
              <div class="m-3">
                <label for="qoute_municipality">
                  {{ $t("OfferteFormulier.gemeente") }}
                </label>
                <input
                  id="qoute_municipality"
                  class="form-control rounded-pill"
                  v-model="qoute_municipality"
                  required
                />
              </div>
              <div
                class="m-3"
                v-if="$store.getters.getCustomizationNumber != 4"
              >
                <label for="qoute_country">{{
                  $t("OfferteFormulier.land")
                }}</label>
                <input
                  id="qoute_country"
                  class="form-control rounded-pill"
                  v-model="qoute_country"
                  required
                />
              </div>
              <div
                class="m-3"
                v-if="$store.getters.getCustomizationNumber != 4"
              >
                <label for="qoute_country">{{
                  $t("OfferteFormulier.welkeDealer")
                }}</label>
                <input
                  id="qoute_dealer"
                  class="form-control rounded-pill"
                  v-model="qoute_dealer"
                  required
                />
              </div>
              <div class="m-3 d-grid border border-primary rounded-pill p-2">
                <button
                  type="submit"
                  class="btn btn-white border-light shadow-sm rounded-pill font-egdl"
                >
                  {{
                    $store.getters.getCustomizationNumber != 4
                      ? $t("Knoppen.Mail")
                      : $t("Knoppen.MailVerdouw")
                  }}
                </button>
              </div>
            </form>
          </div>
          <!-- END QOUTE FORM//-->
          <div class="row g-3 m-3">
            <div class="col-12" v-if="this.debug">
              <div class="d-grid border border-primary rounded-pill p-2">
                <button
                  class="btn btn-white border-light shadow-sm rounded-pill font-egdl"
                  v-on:click.prevent="debugJSON"
                >
                  DEBUG JSON
                </button>
              </div>
            </div>
            <div class="col-6" v-if="Token != 'INVALID'">
              <div class="d-grid border border-primary rounded-pill p-2">
                <button
                  class="btn btn-white border-light shadow-sm rounded-pill font-egdl"
                  v-on:click.prevent="toEGDesign"
                >
                  {{ $t("Knoppen.Opslaan") }}
                </button>
              </div>
            </div>
            <div class="col-6" v-if="Token != 'INVALID'">
              <div class="d-grid border border-primary rounded-pill p-2">
                <button
                  class="btn btn-white border-light shadow-sm rounded-pill font-egdl"
                  v-on:click.prevent="backToEgdl"
                >
                  {{ $t("Knoppen.Terug") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END col-6 //-->
      </div>
    </form>
    <LoginScreen />
    <PrintScreen />
    <div style="display: none">{{ $store.state.version }}</div>
  </div>
</template>

<script>
import DrawScreen from "@/components/DrawScreen.vue";
import ColorSelect from "@/components/ColorSelect.vue";
import LocalChange from "@/components/LocalChange.vue";
import HelpDeurmaten from "@/components/help/HelpDeurmaten.vue";
import HelpDeurkader from "@/components/help/HelpDeurkader.vue";
import HelpDraaipunt from "@/components/help/HelpDraaipunt.vue";
import HelpDeurgrepen from "@/components/help/HelpDeurgrepen.vue";
import HelpOnderverdeling from "@/components/help/HelpOnderverdeling.vue";
import HelpScharnieren from "@/components/help/HelpScharnieren.vue";
import HelpTrekkerMetSlot from "@/components/help/HelpTrekkerMetSlot.vue";
import AlTools from "@/components/AlTools.vue";
import PrintScreen from "@/components/PrintScreen.vue";
import LoginScreen from "@/components/LoginScreen.vue";
import PriceCalcScreen from "@/components/PriceCalcScreen.vue";
import LogoScreen from "@/components/LogoScreen.vue";
import HelpMagneetsluiting from "@/components/help/HelpMagneetsluiting.vue";
import HelpPositieScharnieren from "@/components/help/HelpPositieScharnieren.vue";
import HelpGreeptype from "@/components/help/HelpGreeptype.vue";
import NexSpeling from "@/components/NexSpeling";

export default {
  name: "HomeView",
  components: {
    DrawScreen,
    ColorSelect,
    HelpDeurmaten,
    HelpDeurkader,
    HelpDraaipunt,
    HelpDeurgrepen,
    HelpOnderverdeling,
    HelpScharnieren,
    HelpPositieScharnieren,
    HelpTrekkerMetSlot,
    HelpMagneetsluiting,
    HelpGreeptype,
    LocalChange,
    AlTools,
    PrintScreen,
    LoginScreen,
    PriceCalcScreen,
    LogoScreen,
    NexSpeling,
  },
  data() {
    return { qouteFormSubmitted: false };
  },
  computed: {
    klantRef: {
      get() {
        return this.$store.state.order.klantref;
      },
      set(value) {
        this.$store.commit("setKlantRef", value);
      },
    },
    debug: {
      get() {
        return this.$store.state.debug;
      },
      set(value) {
        this.$store.commit("setDebug", value);
      },
    },
    deurgeheelRef: {
      get() {
        return this.$store.state.deurgeheelRef;
      },
      set(value) {
        this.$store.commit("setDeurgeheelRef", value);
      },
    },
    qoute_firstname: {
      get() {
        return this.$store.state.qouteForm.firstname;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "firstname",
          value: value,
        });
      },
    },
    qoute_lastname: {
      get() {
        return this.$store.state.qouteForm.lastname;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "lastname",
          value: value,
        });
      },
    },
    qoute_phone: {
      get() {
        return this.$store.state.qouteForm.phone;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "phone",
          value: value,
        });
      },
    },
    qoute_mail: {
      get() {
        return this.$store.state.qouteForm.mail;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "mail",
          value: value,
        });
      },
    },
    qoute_country: {
      get() {
        return this.$store.state.qouteForm.country;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "country",
          value: value,
        });
      },
    },
    qoute_street: {
      get() {
        return this.$store.state.qouteForm.street;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "street",
          value: value,
        });
      },
    },
    qoute_zip: {
      get() {
        return this.$store.state.qouteForm.zip;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "zip",
          value: value,
        });
      },
    },
    qoute_municipality: {
      get() {
        return this.$store.state.qouteForm.municipality;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "municipality",
          value: value,
        });
      },
    },
    qoute_dealer: {
      get() {
        return this.$store.state.qouteForm.dealer;
      },
      set(value) {
        this.$store.commit("setQouteField", {
          key: "dealer",
          value: value,
        });
      },
    },
    user_name: {
      get() {
        return this.$store.state.sec.user_name;
      },
      set(value) {
        this.$store.commit("setUserName", value);
      },
    },
    user_pwd: {
      get() {
        return this.$store.state.sec.user_pwd;
      },
      set(value) {
        this.$store.commit("setUserPwd", value);
      },
    },
    productAssortiment: {
      get() {
        return this.$store.state.productAssortiment;
      },
      set(value) {
        this.$store.commit("setproductAssortiment", value);
      },
    },
    scharnierenPositieType: {
      get() {
        return this.$store.state.scharnierenPositieType;
      },
      set(value) {
        this.$store.commit("setScharnierenPositieType", value);
      },
    },
    scharnierType: {
      get() {
        return this.$store.state.scharnierType;
      },
      set(value) {
        this.$store.commit("setScharnierType", value);
      },
    },
    productType: {
      get() {
        return this.$store.state.productType;
      },
      set(value) {
        this.$store.commit("setproductType", value);
      },
    },
    aantalDeuren: {
      get() {
        return this.$store.state.aantalDeuren;
      },
      set(value) {
        this.$store.commit("setAantalDeuren", value);
      },
    },
    frameKeuze: {
      get() {
        if (this.$store.state.aantalDeuren >= 1) {
          return this.$store.state.aldv[this.$store.state.alustaDeurKeuze]
            .navisioncode;
        } else {
          return this.$store.state.ALF[this.$store.state.alustaDeurKeuze]
            .navisioncode;
        }
      },
    },
    draairichting: {
      get() {
        return this.$store.state.draairichting;
      },
      set(value) {
        this.$store.commit("setDraairichting", value);
      },
    },
    deurkaderKeuze: {
      get() {
        return this.$store.state.deurkaderKeuze;
      },
      set(value) {
        this.$store.commit("setDeurkaderKeuze", value);
      },
    },
    ALOkeuze: {
      get() {
        return this.$store.state.ALOkeuze;
      },
      set(value) {
        this.$store.commit("setALOkeuze", value);
      },
    },
    glassoortKeuze: {
      get() {
        return this.$store.state.glassoortKeuze;
      },
      set(value) {
        this.$store.commit("setGlassoortKeuze", value);
      },
    },
    hendelKeuze: {
      get() {
        return this.$store.state.hendelKeuze;
      },
      set(value) {
        this.$store.commit("setHendelKeuze", value);
        if ((value >= 1 && value <= 8) || (value >= 28 && value <= 29)) {
          if (this.hendelPositieX == 0) this.hendelPositieX = 80;
        } else this.hendelPositieX = 0;
      },
    },
    greeplengte: {
      get() {
        return this.$store.state.greeplengte;
      },
      set(value) {
        this.$store.commit("setGreeplengte", value);
      },
    },
    hendelPositieX: {
      get() {
        return this.$store.state.hendelPositie.x;
      },
      set(value) {
        this.$store.commit("setHendelPositieX", value);
      },
    },
    magneetPositieY: {
      get() {
        return this.$store.state.magneetPositie.y;
      },
      set(value) {
        this.$store.commit("setMagneetPositieY", value);
      },
    },
    hendelPositieY: {
      get() {
        return this.$store.state.hendelPositie.y;
      },
      set(value) {
        this.$store.commit("setHendelPositieY", value);
      },
    },
    slot: {
      get() {
        return this.$store.state.slot;
      },
      set(value) {
        this.$store.commit("setSlot", value);
      },
    },
    slotpot: {
      get() {
        return this.$store.state.slotpot;
      },
      set(value) {
        this.$store.commit("setSlotPot", value);
      },
    },
    cilinderkleur: {
      get() {
        return this.$store.state.cilinderkleur;
      },
      set(value) {
        this.$store.commit("setCilinderKleur", value);
      },
    },
    cilindertype: {
      get() {
        return this.$store.state.cilindertype;
      },
      set(value) {
        this.$store.commit("setCilinderType", value);
      },
    },
    middenslot: {
      get() {
        return this.$store.state.middenslot;
      },
      set(value) {
        this.$store.commit("setMiddenslot", value);
      },
    },
    slotplaat: {
      get() {
        return this.$store.state.slotplaat;
      },
      set(value) {
        this.$store.commit("setSlotplaat", value);
      },
    },
    slotX: {
      get() {
        return this.$store.state.slotX;
      },
      set(value) {
        this.$store.commit("setSlotX", value);
      },
    },
    kantschuifslot: {
      get() {
        return this.$store.state.kantschuifslot;
      },
      set(value) {
        this.$store.commit("setKantschuifslot", value);
      },
    },
    asymmetrisch: {
      get() {
        return this.$store.state.asymmetrisch;
      },
      set(value) {
        this.$store.commit("setAsymmetrisch", value);
        this.$store.commit(
          "setVerticaleOnderverdelingen",
          this.$store.state.verticaleOnderverdeling
        );
        this.$store.commit(
          "setHorizontaleOnderverdelingen",
          this.$store.state.horizontaleOnderverdeling
        );
      },
    },
    aantalPanelenLinks: {
      get() {
        return this.$store.state.aantalPanelenLinks;
      },
      set(value) {
        this.$store.commit("setAantalPanelenLinks", value);
      },
    },
    aantalPanelenRechts: {
      get() {
        return this.$store.state.aantalPanelenRechts;
      },
      set(value) {
        this.$store.commit("setAantalPanelenRechts", value);
      },
    },
    horizontaleOnderverdeling: {
      get() {
        return this.$store.state.horizontaleOnderverdeling;
      },
      set(value) {
        this.$store.commit("setHorizontaleOnderverdeling", value);
        this.$store.commit("setHorizontaleOnderverdelingen", value);
      },
    },
    verticaleOnderverdeling: {
      get() {
        return this.$store.state.verticaleOnderverdeling;
      },
      set(value) {
        this.$store.commit("setVerticaleOnderverdeling", value);
        this.$store.commit("setVerticaleOnderverdelingen", value);
      },
    },
    draaipuntX: {
      get() {
        return this.$store.state.draaipuntX;
      },
      set(value) {
        this.$store.commit("setDraaipuntX", value);
        this.$store.commit("calculateDraaipuntWarning");
      },
    },
    Bkader: {
      get() {
        return this.$store.state.kadermaat.Breedte;
      },
      set(value) {
        this.$store.commit("setBreedte", value);
        this.$store.commit("calculateX");
        this.$store.commit("calculateDraaipuntWarning");
        this.$store.commit("setGewicht");
        if (!this.$store.state.asymmetrisch) {
          this.$store.commit(
            "setVerticaleOnderverdelingen",
            this.$store.state.verticaleOnderverdeling
          );
        }
      },
    },
    Bdeurkader: {
      get() {
        return this.$store.state.deurkadermaat.Breedte;
      },
      set(value) {
        this.Bkader = value;
        //this.$store.commit("setDeurkaderBreedte", value);
      },
    },
    Hkader: {
      get() {
        return this.$store.state.kadermaat.Hoogte;
      },
      set(value) {
        this.$store.commit("setHoogte", value);
        this.$store.commit("setGewicht");
        if (!this.$store.state.asymmetrisch) {
          this.$store.commit(
            "setHorizontaleOnderverdelingen",
            this.$store.state.horizontaleOnderverdeling
          );
        }
      },
    },
    Hdeurkader: {
      get() {
        return this.$store.state.deurkadermaat.Hoogte;
      },
      set(value) {
        this.Hkader = value;
      },
    },
    Bglas: {
      get() {
        return this.$store.state.glasmaat.Breedte;
      },
      set(value) {
        this.$store.commit("setGlasBreedte", value);
      },
    },
    Hglas: {
      get() {
        return this.$store.state.glasmaat.Hoogte;
      },
      set(value) {
        this.$store.commit("setGlasHoogte", value);
      },
    },
    Token: {
      get() {
        return this.$store.state.token;
      },
      set(value) {
        this.$store.commit("setToken", value);
      },
    },
    opvulvlak: {
      get() {
        return this.$store.state.opvulvlak;
      },
      set(value) {
        this.$store.commit("setOpvulvlak", value);
      },
    },
    schuifdeurZijspeling: {
      get() {
        return this.$store.state.schuifdeurZijspeling;
      },
      set(value) {
        this.$store.commit("setSchuifdeurZijspeling", value);
      },
    },
  },
  mounted: function () {
    if (this.$route.query.orderID) {
      this.$store.commit("setOrderID", this.$route.query.orderID);
    }
    if (this.$route.query.token) {
      this.Token = this.$route.query.token;
    } else {
      this.Token = "INVALID";
    }
    if (this.Token != "INVALID") {
      this.$store.commit("toggleShowPrice");
      this.$store.state.priceFix++;
    }
    if (this.$route.query.lang) {
      this.$i18n.locale = this.$route.query.lang;
    }
    if (this.$route.query.debug) {
      this.debug = true;
    }
    this.Hkader = 2100;
    this.Bkader = 900;
    /*var self = this;
    window.addEventListener(
      "message",
      (event) => {
        if (event.origin == "https://egdesign.euroglas-dl.com") {
          console.log(event);
          //event.source.close();
          self.Token = event.data;
          if (self.Token == "INVALID") {
            alert(this.$t("ErrorAlert.InvalidToken"));
          }
          self.toEGDesign();
        }
      },
      false
    );*/
  },
  methods: {
    /*login() {
      var ref = window.open(
        "https://egdesign.euroglas-dl.com/egdesign/api/sso.php",
        "_blank"
      );
      setTimeout(function () {
        ref.postMessage(
          "Give me a token please!",
          "https://egdesign.euroglas-dl.com"
        );
      }, 500);
    },*/
    selectProductAssortiment(type) {
      this.productAssortiment = type;
      this.setSlot(0);
      this.setKantschuifslot(0);
      this.$store.commit("setScharnierKleur", 0);
      if (type == 1) {
        this.selectProductType(1, 1);
        this.setHendelKeuze(16);
        this.setAantalPanelenLinks(0);
        this.setAantalPanelenRechts(0);
        this.setGlassoortKeuze(1);
        this.$store.commit("resetAllHelpProperties");
        this.Hdeurkader = 2400;
        this.Bdeurkader = 800;
      } else {
        this.setHendelKeuze(0);
        this.setDeurkaderKeuze(0);
        this.setGlassoortKeuze(0);
        this.$store.commit("resetAllHelpProperties");
        this.Hkader = 2100;
        this.Bkader = 900;
      }
    },
    selectProductType(type, aantal) {
      this.productType = type;
      this.aantalDeuren = aantal;
    },
    selectAantalDeuren(aantal) {
      this.aantalDeuren = aantal;
    },
    selectDraairichting(richting) {
      this.draairichting = richting;
    },
    setAantalPanelenLinks(aantal) {
      this.aantalPanelenLinks = aantal;
    },
    setAantalPanelenRechts(aantal) {
      this.aantalPanelenRechts = aantal;
    },
    setAantalHorizontaleOnderverdeling(aantal) {
      this.horizontaleOnderverdeling = aantal;
    },
    setAantalVerticaleOnderverdeling(aantal) {
      this.verticaleOnderverdeling = aantal;
    },
    setHendelKeuze(index) {
      this.hendelKeuze = index;
      if (this.hendelKeuze == 14) {
        this.setMiddenslot(1);
      }

      if (this.$store.state.hendel[this.hendelKeuze].p_middenslot == 0) {
        this.setMiddenslot(0);
      }
    },
    setGlassoortKeuze(index) {
      this.glassoortKeuze = index;
    },
    setSlot(index) {
      this.slot = index;
    },
    setSlotPot(index) {
      this.slotpot = index;
    },
    setCilinderKleur(index) {
      this.cilinderkleur = index;
    },
    setCilinderType(index) {
      this.cilindertype = index;
    },
    setMiddenslot(index) {
      this.middenslot = index;
    },
    setSlotplaat(index) {
      this.slotplaat = index;
    },
    setKantschuifslot(index) {
      this.kantschuifslot = index;
    },
    setOpvulvlak(index) {
      this.opvulvlak = index;
    },
    setALOkeuze(keuze) {
      this.ALOkeuze = keuze;
      if (this.ALOkeuze == 1) {
        this.setOpvulvlak(0);
      }
    },
    setDeurkaderKeuze(keuze) {
      this.deurkaderKeuze = keuze;
      if (keuze == 2) {
        this.Hkader = 2100;
        this.Bkader = 900;
        this.setScharnierType(0);
      } else {
        if (keuze == 0) {
          this.setScharnierType(0);
        } else {
          this.setScharnierType(1);
        }
        this.Hdeurkader = 2400;
        this.Bdeurkader = 800;
      }
    },
    setScharnierenPositieType(index) {
      if (index == 0) {
        this.$store.commit("updateScharnieren", {
          id: 0,
          value: 150,
        });
        this.$store.commit("updateScharnieren", {
          id: 1,
          value: 150,
        });
        this.$store.commit("updateScharnieren", {
          id: 2,
          value: 340,
        });
        this.$store.commit("updateScharnieren", {
          id: 3,
          value: 1050,
        });
      }
      this.scharnierenPositieType = index;
    },
    setScharnierType(keuze) {
      this.scharnierType = keuze;
    },
    updateScharnieren(e, index) {
      this.$store.commit("updateScharnieren", {
        id: index,
        value: e.target.value,
      });
    },
    updatePaneelLinks(e, index) {
      this.$store.commit("updatePaneelLinks", {
        id: index,
        value: e.target.value,
      });
    },
    updatePaneelRechts(e, index) {
      this.$store.commit("updatePaneelRechts", {
        id: index,
        value: e.target.value,
      });
    },
    updateHorizontaleOnderverdeling(e, index) {
      this.$store.commit("updateHorizontaleOnderverdeling", {
        id: index,
        value: e.target.value,
      });
    },
    updateVerticaleOnderverdeling(e, index) {
      this.$store.commit("updateVerticaleOnderverdeling", {
        id: index,
        value: e.target.value,
      });
    },
    checkForm() {
      const elementList = document.querySelectorAll(".is-invalid");
      /*&&
        this.Bglas > 100 &&
        this.Bkader <= 1500 &&
        this.Hglas > 100 &&
        this.Hkader <= 3000 &&
        this.draaipuntX <= this.Bkader / 2 &&
        this.draaipuntX >= 110*/
      if (elementList.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    addWarnings() {
      if (this.$store.state.toonDraaipuntWaarschuwing)
        this.$store.commit("addHiddenRemark", this.$t("Draaipunt.Error01"));
    },
    async debugJSON() {
      const theOrder = await this.$store.dispatch("generateOrderJson");
      console.log(theOrder);
    },
    async toEGDesign() {
      const self = this;
      const lang = this.$i18n.locale;
      if (
        (this.Token != "INVALID" ||
          (this.user_name != "" && this.user_pwd != "")) &&
        this.checkForm()
      ) {
        //add warnings etc...
        this.addWarnings();
        const theOrder = await this.$store.dispatch("generateOrderJson");
        if (theOrder != 0) {
          const id = await this.$store.dispatch("saveToEGDesign", theOrder);
          if (id != 0) {
            //if (confirm("Edit in EGDesign?") == true) {
            location.replace(
              "https://egdesign.euroglas-dl.com/egdesign/order.php?orderID=" +
                id +
                "&token=" +
                self.Token +
                "&lang=" +
                lang
            );
            //}
          } else {
            alert(this.$t("ErrorAlert.ErLiepIetsMis"));
          }
        }
      } else {
        if (this.Token != "INVALID") {
          alert(this.$t("ErrorAlert.VulAllesIn"));
        }
      }
    },
    async sendMail() {
      var form = document.getElementById("qouteForm");
      const lang = this.$i18n.locale;
      if (form.checkValidity() && this.checkForm()) {
        //add warnings etc...
        this.addWarnings();
        const theOrder = await this.$store.dispatch("generateOrderJson");
        if (theOrder != 0) {
          const id = await this.$store.dispatch(
            "saveToEGDesignAdmin",
            theOrder
          );
          if (id != 0) {
            await this.$store.dispatch("sendMail", lang);
            this.qouteFormSubmitted = true;
          } else {
            alert(this.$t("ErrorAlert.ErLiepIetsMis"));
          }
        }
      } else {
        alert(this.$t("ErrorAlert.VulAllesIn"));
      }
    },
    async exportOrder() {
      const elementList = document.querySelectorAll(".is-invalid");
      if (elementList.length == 0) {
        const theOrder = await this.$store.dispatch("generateOrderJson");
        if (theOrder != 0) {
          if (confirm("Download export file?") == true) {
            this.download("test.txt", JSON.stringify(theOrder));
          }
        } else {
          alert(this.$t("ErrorAlert.ErLiepIetsMis"));
        }
      } else {
        alert(this.$t("ErrorAlert.VulAllesIn"));
      }
    },
    backToEgdl() {
      location.replace(
        "https://egdesign.euroglas-dl.com/egdesign/start.php?token=" +
          this.Token +
          "&lang=" +
          this.$i18n.locale
      );
    },
    download(filename, textInput) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8, " + encodeURIComponent(textInput)
      );
      element.setAttribute("download", filename);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>
