export default {
  "Base": {
    "Links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinistra"])},
    "Rechts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destro"])},
    "Verticaal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verticale"])},
    "Horizontaal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orizzontale"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica visualizzazione"])},
    "greeplengte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza della maniglia"])},
    "Design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "Gebruiksvriendelijkheid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilità d'uso"])}
  },
  "MainTitle": {
    "Deuren/panelen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte/pannelli"])},
    "Kaderverdeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suddivisione del telaio"])},
    "Deurgrepen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maniglie delle porte"])},
    "Glas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vetro"])},
    "Extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiuntivo"])},
    "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colore e finitura"])},
    "Pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolo del prezzo"])},
    "Deurkaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telaio della porta"])},
    "ScharnierType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipo di cerniera"])}
  },
  "SubTitle": {
    "Producttype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di prodotto"])},
    "Deurtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di porta"])},
    "Draairichting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione di apertura della porta"])},
    "Openingsrichting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione di apertura"])},
    "Deurmaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensioni della porte/panello"])},
    "Panelen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero e dimensioni dei pannelli aggiuntivi"])},
    "Draaipunt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza del punto di rotazione della porta"])},
    "Aantal/positie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità/posizione suddivisione"])},
    "Greeptype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di maniglia"])},
    "Maten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensioni"])},
    "Positie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione maniglia"])},
    "MetMiddenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con serratura centrale"])},
    "Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piastra di bloccaggio"])},
    "Glassoorten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipi di vetro"])},
    "Deurslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serratura nella plinta inferiore"])},
    "SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bocchetta della serratura"])},
    "Cilinders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cilindro"])},
    "ALOType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di profilo"])},
    "Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pannello di riempimento"])},
    "Ralkleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL colore"])},
    "Lakafwerking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finitura RAL"])},
    "Anodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC"])},
    "Zijspeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gioco laterale"])},
    "HingeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colore delle cerniere"])},
    "AsTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOLS"])},
    "Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiavistello scorrevole laterale"])},
    "Deurkadermaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensioni del telaio della porta"])},
    "Scharnieren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione delle cerniere"])},
    "Magneetsluiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione della chiusura magnetica"])}
  },
  "Producttype": {
    "Hydraulische": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta girevole idraulica"])},
    "Paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singolo pannello fisso"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le combinazioni di porte e pannelli possono essere configurate secondo le vostre preferenze durante la procedura."])},
    "Sliding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta scorrevole"])}
  },
  "Deurtype": {
    "Enkel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta singola"])},
    "Dubbel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta doppia"])}
  },
  "Deurmaten": {
    "Hoogte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta/pannello altezza"])},
    "Breedte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta/pannello larghezza"])},
    "Info01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le misure dell'apertura per le dimensioni della porta sopra sono: ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Info02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le dimensioni di battuta per le dimensioni delle porte sopra indicate sono: ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Error01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["L'altezza massima è di ", _interpolate(_list(0)), " mm!"])},
    "Error02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La larghezza massima è di ", _interpolate(_list(0)), " mm!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il peso della porta non può superare i 100 kg. Si prega di ridurre le dimensioni della porta."])}
  },
  "Deurkader": {
    "Hoogte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza telaio della porta"])},
    "Breedte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larghezza telaio della porta"])},
    "SpelingSlotkastzijde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esterno porta lato chiusura / Interno lato del telaio porta"])},
    "SpelingBoven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte superiore porta / Base lato superiore telaio porta"])},
    "SpelingOnder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base porta / Parte superiore del pavimento"])},
    "SpelingInfo01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tolleranze sopra indicate influenzano le dimensioni della porta."])},
    "Nex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX. TELAIO PORTA"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GP. TELAIO PORTA"])},
    "Zonder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SENZA TELAIO PORTA"])},
    "Info01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le misure di spessore per le dimensioni sopra indicate del telaio porta, tenendo conto delle tolleranze suggerite, sono:  ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Info02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le misure della porta per le dimensioni sopra indicate del telaio porta, tenendo conto delle tolleranze suggerite, sono:   ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In base all'altezza della porta, viene determinato il numero di cerniere."])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'altezza massima è di 3000 mm!"])},
    "Error02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La larghezza massima è di 1500 mm!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il peso della porta non può superare i 100 kg. Si prega di ridurre le dimensioni della porta."])}
  },
  "ScharnierType": {
    "Nex05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX05"])},
    "DF05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DF05"])}
  },
  "Scharnieren": {
    "Standaard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "OpMaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizzato"])}
  },
  "AsTools": {
    "TextTool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato che la larghezza della porta è superiore a 1010 mm, si consiglia di acquistare ASTOOL1 e ASTOOL2 per regolare la velocità di chiusura e l'asse superiore"])},
    "TextToolXL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato che la larghezza della porta è superiore a 1200 mm, si consiglia di acquistare ASTOOL1XL e ASTOOL2XL per regolare la velocità di chiusura e l'asse superiore."])},
    "AsTool1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 1 - L: 300mm"])},
    "AsTool2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 2 - L: 300mm"])},
    "AsTool1XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 1XL - L: 600mm"])},
    "AsTool2XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 2XL - L: 600mm"])}
  },
  "Panelen": {
    "PaneelNum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pannello ", _interpolate(_list(0)), " (mm)"])},
    "AfwijkendeSituatie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situazioni speciali"])},
    "AfwijkendeSituatieLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/afwijkende_panelen.pdf"])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La larghezza massima è di 1500 mm!"])},
    "Asymmetrisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asimmetrico"])},
    "TProfiel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo a T"])},
    "IProfiel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo a I"])},
    "ProfielInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se selezioni un maniglia di trazione AMUR SQ o AMT e li posizioni su un profilo, si consiglia di scegliere un profilo a I. Un profilo a T è tecnicamente possibile, ma è un'opzione più costosa e meno user-friendly a causa del bordo rialzato."])}
  },
  "Draaipunt": {
    "Afstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distenza"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza predefinita è la scelta più raccomandata per le dimensioni della porta sopra."])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si consiglia di spostare ulteriormente il punto di rotazione. La posizione scelta non è coperta dalla garanzia."])},
    "Error02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il punto di rotazione non può essere più distante dalla metà della larghezza della porta (", _interpolate(_list(0)), " mm)!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punto di rotazione non può essere più piccolo di 110 mm!"])}
  },
  "Zijspeling": {
    "Afstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gioco tra parete e porta scorrevole Alusta"])}
  },
  "Greeptype": {
    "Hoekgreep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maniglia ad angolo"])},
    "AMURSQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ maniglia di trazione"])},
    "AMT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT maniglia di trazione"])},
    "NEXHG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia a L"])},
    "NEXEG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia ergonomica a L"])},
    "NXCS01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia della porta con serratura"])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa maniglia è troppo grande per questa porta."])}
  },
  "Maten": {
    "MetMiddenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maniglia ad angolo con serratura centrale"])}
  },
  "Glassoort": {
    "Klaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiaro (10 mm)"])},
    "Grijs10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grigio (10 mm)"])},
    "Brons10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronzo (10 mm)"])},
    "AcideKlaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acidato Chiaro (10 mm)"])},
    "AcideExtraKlaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acidato Extra-Chiaro (10 mm)"])},
    "Kathedraal8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kathedral (8 mm)"])},
    "Kathedraal6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kathedral piccolo (6 mm)"])},
    "Raywall8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raywall (8 mm)"])},
    "Klaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiaro (6 mm)"])},
    "ExtraKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra-Chiaro (6 mm)"])},
    "Grijs06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grigio (6 mm)"])},
    "Brons06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronzo (6 mm)"])},
    "Crepi06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crépi (6 mm)"])},
    "AcideKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acidato Chiaro (6 mm)"])},
    "AcideExtraKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acidato Extra-Chiaro (6 mm)"])},
    "DarkGrey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark Grey (6 mm)"])}
  },
  "Slot": {
    "SlotX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serratura X (mm)"])},
    "0Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza serratura"])},
    "1Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con serratura"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La serratura si trova nel profilo inferiore. Per aprire e chiudere la porta, dovrai chinarti."])},
    "0SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza bocchetta della serratura"])},
    "1SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con bocchetta della serratura"])},
    "0CilinderKleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodizzato nero"])},
    "1CilinderKleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cromato lucido"])},
    "0CilinderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con chiave unica"])},
    "1CilinderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza chiave unica"])},
    "0Middenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maniglia senza serratura centrale"])},
    "1Middenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maniglia con serratura centrale"])},
    "0Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza piastra di bloccaggio"])},
    "1Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con piastra di bloccaggio"])},
    "0Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza chiavistello scorrevole laterale"])},
    "1Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiavistello scorrevole laterale nero opaco"])},
    "2Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiavistello scorrevole laterale alluminio opaco"])}
  },
  "Opvulvlak": {
    "0Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza pannello di riempimento"])},
    "1Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In basso"])},
    "2Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al centro"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il pannello di riempimento si trova sotto la porta fino alla prima suddivisione orizzontale."])}
  },
  "Knoppen": {
    "Opslaan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva in EGDesign"])},
    "Terug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna a EGDesign senza salvare"])},
    "Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "MailVerdouw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine"])}
  },
  "ErrorAlert": {
    "ErLiepIetsMis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcosa è andato storto!"])},
    "VulAllesIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila tutto correttamente!"])},
    "InvalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiave errata"])}
  },
  "HelpDeurgrepen": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione orizzontale (h) della maniglia della porta viene determinata dalla linea centrale della maniglia della porta fino al bordo destro estremo dell'anta della porta."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione verticale (v) della maniglia della porta viene determinata dal centro della maniglia della porta fino al bordo inferiore del telaio della porta."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri la posizione della maniglia ad angolo come mostrato nella visualizzazione qui accanto, devi inserire il seguente valore per la posizione verticale: ½ x lunghezza della maniglia ad angolo + 68."])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri la posizione della maniglia ad angolo come mostrato nella visualizzazione qui accanto, devi inserire il seguente valore per la posizione verticale: ½ x lunghezza della maniglia ad angolo."])}
  },
  "HelpDeurmaten": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza della porta = altezza del pannello fisso."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tieni conto delle seguenti tolleranze consigliate:"])},
    "Deur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta"])},
    "vloer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavimento"])},
    "plafond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soffitto"])},
    "muur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parete"])},
    "deur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta"])},
    "paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pannello"])},
    "Paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pannello"])},
    "Opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apertura"])},
    "zijstijlen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipi Laterali telai di porta"])},
    "bovenstijl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stile superiore telai di porta"])},
    "Deurkaderbreedte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larghezza telai di porta"])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le misure di apertura sono le dimensioni più piccole in altezza e larghezza misurate nell'apertura dove devono essere posizionate la/e porta/e o il/i pannello/i."])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per una porta doppia, la larghezza della porta inserita verrà raddoppiata."])},
    "RekenvoorbeeldLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/rekenvb_config_it.pdf"])},
    "Rekenvoorbeeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica esempio di calcolo"])}
  },
  "HelpDeurkader": {
    "NEXTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telaio della porta NEX."])},
    "NexInfo01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telaio NEX. è fornito di serie con cerniere NEX05R/L."])},
    "NexInfo02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cerniere NEX05L/R sono state sviluppate appositamente per essere utilizzate in combinazione con il telaio della porta NEX, che puoi selezionare nel configuratore."])},
    "NexInfo03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile modificare la posizione delle cerniere nel configuratore."])},
    "NexInfo04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando determini la posizione delle cerniere e le dimensioni della porta, ti consigliamo di utilizzare il tuo telaio della porta specifico come punto di riferimento."])},
    "NexInfo05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri utilizzare queste cerniere altrove, ti consigliamo di consultare la scheda tecnica delle aperture sul sito web."])},
    "NexInfo06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri modificare le giunzioni tra l'esterno del telaio della porta e l'apertura, dovrai modificare le dimensioni del telaio della porta. Tieni presente che la modifica delle giunzioni sarà visibile nelle misure calcolate."])},
    "NexInfo07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telaio NEX non consente di combinare con lucernari laterali."])},
    "Nex": {
      "Particulier": {
        "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile cambiare il colore delle cerniere in modo diverso rispetto agli altri componenti tramite il rivenditore."])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile ordinare solo il telaio NEX. senza porta con cerniere tramite il rivenditore."])}
      },
      "Dealer": {
        "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile cambiare il colore delle cerniere in EGDesign."])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile ordinare solo il telaio NEX. rimuovendo la porta in EGDesign."])}
      }
    },
    "GPTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telaio della porta GP."])},
    "GPInfo01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telaio GP. è fornito di serie con cerniere DF05R/L."])},
    "GPInfo02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cerniere DF05L/R sono state sviluppate appositamente per essere utilizzate nella nostra gamma esistente di telai per porte (GP, SF, SW) e nei telai in legno."])},
    "GPInfo03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile modificare la posizione delle cerniere nel configuratore."])},
    "GPInfo04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando determini la posizione delle cerniere e le dimensioni della porta, ti consigliamo di utilizzare il tuo telaio della porta specifico come punto di riferimento."])},
    "GPInfo05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri utilizzare queste cerniere altrove, ti consigliamo di consultare la scheda tecnica delle aperture sul sito web."])},
    "GPInfo06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri modificare le giunzioni tra l'esterno del telaio della porta e l'apertura, dovrai modificare le dimensioni del telaio della porta. Tieni presente che la modifica delle giunzioni sarà visibile nelle misure calcolate."])},
    "GP": {
      "Particulier": {
        "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile cambiare il colore delle cerniere in modo diverso rispetto agli altri componenti tramite il rivenditore."])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile ordinare solo il telaio GP. senza porta con cerniere tramite il rivenditore."])}
      },
      "Dealer": {
        "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile cambiare il colore delle cerniere in EGDesign."])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile ordinare solo il telaio GP. rimuovendo la porta in EGDesign."])}
      }
    },
    "GeenTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza telai di porta"])},
    "standaard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolleranze standard:"])},
    "GeenInfo01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non ordini il telaio della porta NEX o GP, ti consigliamo di consultare le giunzioni sopra riportate."])},
    "RekenvoorbeeldLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/rekenvb_configNEX_it.pdf"])},
    "Rekenvoorbeeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica esempio di calcolo"])}
  },
  "HelpAsTools": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per larghezze della porta comprese tra 1010 mm < x ≤ 1200 mm, si consiglia di ordinare ASTOOL1 per regolare la velocità di apertura e chiusura se non si dispone di un cacciavite a testa piatta lungo 300 mm."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per larghezze della porta comprese tra 1010 mm < x ≤ 1200 mm, si consiglia di ordinare ASTOOL2 per regolare l'asse superiore se non si dispone di una chiave esagonale misura 3 con lunghezza 300 mm."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per larghezze della porta comprese tra 1200 mm < x ≤ 1500 mm, si consiglia di ordinare ASTOOL1XL per regolare la velocità di apertura e chiusura se non si dispone di un cacciavite a testa piatta lungo 600 mm."])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per larghezze della porta comprese tra 1200 mm < x ≤ 1500 mm, si consiglia di ordinare ASTOOL2XL per regolare l'asse superiore se non si dispone di una chiave esagonale misura 3 con lunghezza 600 mm."])}
  },
  "HelpDraaipunt": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza del punto di rotazione (a) è la distanza dal centro del perno inferiore al bordo esterno del telaio della porta. Se si salva questa configurazione in EGDesign, sul disegno apparirà il valore a - 19 mm, poiché in questo caso la distanza del punto di rotazione (a) è la distanza dal centro del perno inferiore al bordo esterno del vetro."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per garantire la resistenza strutturale, consigliamo di non modificare la distanza del punto di rotazione prestabilita."])}
  },
  "HelpHingeColor": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di serie forniamo le cerniere nello stesso colore del telaio della porta. Se lo si desidera, le cerniere possono essere fornite a magazzino anche in RAL9005M o Acciaio Inossidabile"])}
  },
  "HelpOnderverdeling": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione della traversa verticale del vetro viene determinata dalla distanza tra la linea centrale della traversa verticale del vetro e il bordo estremo sinistro o destro del telaio della porta, a seconda della direzione di apertura scelta."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione di una traversa orizzontale del vetro viene determinata dalla distanza tra la linea centrale di una traversa orizzontale del vetro e il bordo inferiore del telaio della porta."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di default, le suddivisioni orizzontali e verticali vengono posizionate in modo simmetrico rispetto al vetro. Le diverse superfici di vetro tra le suddivisioni sono quindi uguali (A=A=A) (B=B=B). In caso di suddivisione asimmetrica, la posizione viene determinata rispetto al telaio della porta. Vedi sotto:"])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri posizionare la maniglia della porta al centro di una suddivisione orizzontale, contatta il tuo rivenditore."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione della traversa verticale del vetro viene determinata dalla distanza tra la linea centrale della traversa verticale del vetro e il bordo estremo sinistro o destro del telaio della porta, a seconda della direzione di apertura scelta. Quando si salva questa configurazione in EGDesign, sul disegno comparirà il valore v -19mm perché in questo caso la distanza è determinata dalla linea centrale della traversa verticale del vetro al bordo estremo del vetro."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione di una traversa orizzontale del vetro viene determinata dalla distanza tra la linea centrale di una traversa orizzontale del vetro e il bordo inferiore del telaio della porta. Quando si salva questa configurazione in EGDesign, sul disegno comparirà il valore h -66mm perché in questo caso la distanza è determinata dalla linea centrale della traversa orizzontale del vetro al bordo inferiore del vetro."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di default, le suddivisioni orizzontali e verticali vengono posizionate in modo simmetrico rispetto al vetro. Le diverse superfici di vetro tra le suddivisioni sono quindi uguali (A=A=A) (B=B=B). In caso di suddivisione asimmetrica, la posizione viene determinata rispetto al telaio della porta. Vedi sotto:"])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri posizionare la maniglia della porta al centro di una suddivisione orizzontale, puoi facilmente modificare questo in EGDesign."])}
    },
    "Nex": {
      "Particulier": {
        "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per impostazione predefinita, le suddivisioni orizzontali e verticali sono posizionate simmetricamente rispetto al vetro. Le diverse superfici del vetro tra le suddivisioni sono quindi di dimensioni uguali. (A=A=A) (B=B=B) Con una suddivisione asimmetrica, la posizione viene determinata rispetto all'esterno I della parte inferiore del profilo della porta. Vedi sotto:"])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione della listella verticale del vetro è determinata dalla distanza tra la linea centrale della listella e il bordo più esterno sinistro o destro del telaio della porta, a seconda della direzione di apertura scelta."])},
        "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione di una listella orizzontale del vetro è determinata dalla distanza tra la linea centrale della listella e il bordo inferiore del telaio della porta."])},
        "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si consiglia vivamente di fare in modo che le suddivisioni orizzontali siano posizionate vicino alla maniglia L. Determina l'altezza centrale della maniglia come desideri e applica le seguenti formule:"])},
        "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V1 = V3 – (lunghezza maniglia/2) – 12,5"])},
        "Info06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V2= V3 + (lunghezza maniglia/2) + 12,5"])},
        "Info07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile far passare una suddivisione orizzontale in combinazione con le maniglie L tramite il tuo rivenditore."])},
        "Info08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile far passare una suddivisione orizzontale nella maniglia a leva con serratura. Allinea il centro di entrambi."])},
        "Info09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile aggiungere le suddivisioni I e T della serie FLEX alla tua NEX Hinge tramite il tuo rivenditore."])}
      },
      "Dealer": {
        "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per impostazione predefinita, le suddivisioni orizzontali e verticali sono posizionate simmetricamente rispetto al vetro. Le diverse superfici del vetro tra le suddivisioni sono quindi di dimensioni uguali. (A=A=A) (B=B=B) Con una suddivisione asimmetrica, la posizione viene determinata rispetto all'esterno I della parte inferiore del profilo della porta. Vedi sotto:"])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione della listella verticale del vetro è determinata dalla distanza tra la linea centrale della listella e il bordo più esterno sinistro o destro del telaio della porta, a seconda della direzione di apertura scelta. Quando salvi questa configurazione in EGDesign, nel disegno apparirà il valore h - 12 mm, poiché qui viene determinata la distanza dalla linea centrale della listella al bordo esterno del vetro."])},
        "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione di una listella orizzontale del vetro è determinata dalla distanza tra la linea centrale della listella e il bordo inferiore del telaio della porta. Quando salvi questa configurazione in EGDesign, nel disegno apparirà il valore v - 12 mm, poiché qui viene determinata la distanza dalla linea centrale della listella al bordo inferiore del vetro."])},
        "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si consiglia vivamente di fare in modo che le suddivisioni orizzontali siano posizionate vicino alla maniglia L. Determina l'altezza centrale della maniglia come desideri e applica le seguenti formule:"])},
        "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V1 = V3 – (lunghezza maniglia/2) – 12,5"])},
        "Info06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V2= V3 + (lunghezza maniglia/2) +12,5"])},
        "Info07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri che una suddivisione orizzontale si estenda in combinazione con la maniglia L NEXHG (15 x 25 x 2), devi aggiungere una maniglia L extra in EGDesign. Devi posizionare le 2 maniglie L NEXHG appena sopra e sotto la suddivisione orizzontale. Nel caso della maniglia L NEXEG, è possibile far passare la suddivisione orizzontale. Non è necessario inserire un'altra maniglia tramite EGDesign in questa opzione."])},
        "Info08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile far passare una suddivisione orizzontale nella maniglia a leva con serratura. Allinea il centro di entrambi."])},
        "Info09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile aggiungere le suddivisioni I e T della serie FLEX alla tua NEX Hinge tramite EGDesign. Tieni presente che le suddivisioni non saranno a filo con il telaio della porta. Saranno più profonde."])}
      }
    }
  },
  "HelpGreeptype": {
    "Titel01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Maniglia L"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa maniglia è a filo con l'esterno della porta per ottenere un design molto pulito."])},
    "Titel02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Maniglia ergonomica L"])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa maniglia sporge oltre la porta per aumentare la facilità d'uso."])},
    "Titel03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Maniglia della porta con serratura"])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa maniglia a leva ha una serratura integrata ed è anche molto facile da usare."])},
    "Titel04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maniglia di trazione AMUR SQ"])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa maniglia a tirare è elegante e il suo carattere angolare si abbina perfettamente alla FLEX e NEX."])},
    "Titel05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maniglia di trazione AMT"])},
    "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa maniglia a tirare è elegante e il suo carattere arrotondato è ottimale per la facilità d'uso."])},
    "Info06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viene sempre fornita una chiusura magnetica, indipendentemente dalla scelta della maniglia. Per tutte le maniglie, tranne la maniglia a leva, abbiamo diverse lunghezze disponibili. È anche possibile ordinare maniglie su misura."])}
  },
  "HelpTrekkermetslot": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La serratura centrale viene posizionata al centro del tiretto della porta in combinazione con un tiretto AMURSQ/AMT. Se si desidera posizionare la serratura centrale verticalmente in modo diverso, rivolgersi al proprio rivenditore."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La serratura centrale viene posizionata al centro del tiretto della porta in combinazione con un tiretto AMURSQ/AMT. In EGDesign è possibile posizionare la serratura centrale verticalmente in modo diverso rispetto alla posizione dell'estrattore della porta."])}
    }
  },
  "HelpPositieScharnieren": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In base all'altezza della porta, viene determinato il numero di cerniere."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte fino a 2400 mm di altezza -> 3 cerniere. La distanza tra il centro della cerniera e la parte inferiore della porta è di serie V1=150 mm. La distanza tra il centro della cerniera e la parte superiore della porta è di serie V2=150 mm. La distanza tra il centro della cerniera e la parte superiore della porta è di serie V3=340 mm."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte più alte di 2400 mm -> 4 cerniere. La distanza tra il centro della cerniera e la parte inferiore della porta è di serie V1=150 mm. La distanza tra il centro della cerniera e la parte superiore della porta è di serie V2=150 mm. La distanza tra il centro della cerniera e la parte superiore della porta è di serie V3=340 mm. La distanza tra il centro della cerniera e la parte inferiore della porta è di serie V4=1050 mm."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile modificare la posizione delle cerniere nel configuratore a proprio rischio."])},
      "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se si desidera aggiungere cerniere extra, chiedere consiglio al proprio rivenditore e esaminare insieme le specifiche tecniche."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In base all'altezza della porta, viene determinato il numero di cerniere."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte fino a 2400 mm di altezza -> 3 cerniere. La distanza tra il centro della cerniera e la parte inferiore della porta è di serie V1=150 mm. La distanza tra il centro della cerniera e la parte superiore della porta è di serie V2=150 mm. La distanza tra il centro della cerniera e la parte superiore della porta è di serie V3=340 mm."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte più alte di 2400 mm -> 4 cerniere. La distanza tra il centro della cerniera e la parte inferiore della porta è di serie V1=150 mm. La distanza tra il centro della cerniera e la parte superiore della porta è di serie V2=150 mm. La distanza tra il centro della cerniera e la parte superiore della porta è di serie V3=340 mm. La distanza tra il centro della cerniera e la parte inferiore della porta è di serie V4=1050 mm."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile modificare la posizione delle cerniere nel configuratore a proprio rischio."])},
      "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se si desidera aggiungere cerniere extra, è necessario farlo in EGDesign. Si prega di notare che in EGDesign si calcolano le misure in base ai vetri."])}
    }
  },
  "HelpPositieMagneetsluiting": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione centrale standard (V) del magnete si trova a 1050 mm dal fondo della porta. È possibile aggiungere un magnete extra tramite il tuo rivenditore."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione centrale standard (V) del magnete si trova a 1050mm dal fondo della porta. È possibile aggiungere un magnete extra in EGDesign e posizionarlo."])}
    }
  },
  "HelpPositiegreep": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione verticale (V) delle maniglie L è determinata dal centro della maniglia fino al bordo inferiore della porta."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri la posizione della maniglia L come nella visualizzazione accanto, devi inserire il seguente valore nella posizione verticale: ½ x lunghezza della maniglia angolare + 30"])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri la posizione della maniglia L ergonomica come nella visualizzazione accanto, devi inserire il seguente valore nella posizione verticale: ½ x lunghezza della maniglia angolare + 30"])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri la posizione della maniglia L NEXEG come nella visualizzazione accanto, devi inserire il seguente valore nella posizione verticale: ½ x lunghezza della maniglia L."])},
    "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione verticale (V) della maniglia a leva con serratura è determinata dal centro della leva fino al bordo inferiore della porta."])},
    "Info06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con la maniglia a leva con serratura, la posizione della chiusura magnetica è automaticamente determinata."])},
    "Info07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione verticale (V) della maniglia AMURSQ o AMT è determinata dal centro della maniglia fino al bordo inferiore della porta."])},
    "Info08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*La posizione orizzontale (H) della maniglia AMURSQ o AMT è determinata dalla linea centrale della maniglia fino al bordo più esterno destro della porta."])}
  },
  "HelpRalkleur": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il colore RAL 9005 in combinazione con la finitura strutturata è la finitura standard effetto acciaio e viene sempre tenuto in magazzino, salvo esaurimento scorte."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non trovi il colore RAL desiderato nel configuratore? Chiedi al tuo rivenditore quali colori RAL sono tutti disponibili."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una rappresentazione digitale dei colori non è mai accurata. Chiedi quindi al tuo rivenditore di visualizzare campioni del colore e della finitura desiderati."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desideri dare a determinati accessori una diversa combinazione di colore e finitura? È possibile! Chiedi al tuo rivenditore."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il colore RAL 9005 in combinazione con la finitura strutturata è la finitura standard effetto acciaio ed è sempre disponibile, salvo esaurimento scorte."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il colore RAL desiderato non è disponibile nel configuratore? Attraverso EGDesign è possibile selezionare altri colori RAL."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una rappresentazione digitale dei colori non è mai accurata. Assicurati sempre di fornire campioni ai tuoi clienti."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attraverso EGDesign è possibile assegnare accessori a una diversa combinazione di colore e finitura."])}
    },
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tieni presente che la scelta di un colore e di una finitura diversi potrebbe notevolmente aumentare il tempo di consegna."])}
  },
  "HelpLakafwerking": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando si sceglie un colore RAL, è necessario selezionare una finitura laccata desiderata (strutturata, opaca, lucida)."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per ottenere l'effetto acciaio, si consiglia la finitura strutturata. Richiedi al tuo rivenditore campioni per confrontare le diverse finiture."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando si sceglie un colore RAL, è necessario selezionare una finitura laccata desiderata (strutturata, opaca, lucida)."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per ottenere l'effetto acciaio, si consiglia la finitura strutturata. Assicurati di fornire al tuo cliente campioni delle diverse finiture laccate."])}
    }
  },
  "HelpScharnieren": {
    "NEXTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX05L/R cerniere"])},
    "DF05Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DF05L/R cerniere"])},
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telaio NEX è fornito di serie con cerniere NEX05R/L."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile modificare la posizione delle cerniere nel configuratore."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile cambiare il colore delle cerniere in modo diverso rispetto agli altri componenti tramite il rivenditore."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile ordinare solo il telaio NEX senza porta con cerniere tramite il rivenditore."])},
      "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telaio NEX non consente di combinare con lucernari laterali."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telaio NEX è fornito di serie con cerniere NEX05R/L."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile modificare la posizione delle cerniere nel configuratore."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile cambiare il colore delle cerniere in EGDesign."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile ordinare solo il telaio NEX rimuovendo la porta in EGDesign."])},
      "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telaio NEX non consente di combinare con lucernari laterali."])},
      "InfoNEX01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cerniere NEX05L/R sono state sviluppate appositamente per essere utilizzate in combinazione con il telaio della porta NEX, che puoi selezionare nel configuratore."])},
      "InfoNEX02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri utilizzare queste cerniere altrove, ti consigliamo di consultare la scheda tecnica delle aperture sul sito web."])},
      "InfoNEX03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando determini la posizione delle cerniere e le dimensioni della porta, ti consigliamo di utilizzare il tuo telaio della porta specifico come punto di riferimento."])},
      "InfoDF0501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cerniere DF05L/R sono state sviluppate appositamente per essere utilizzate nella nostra gamma esistente di telai per porte (GP, SF, SW) e nei telai in legno."])},
      "InfoDF0502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri utilizzare queste cerniere altrove, ti consigliamo di consultare la scheda tecnica delle aperture sul sito web."])},
      "InfoDF0503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando determini la posizione delle cerniere e le dimensioni della porta, ti consigliamo di utilizzare il tuo telaio della porta specifico come punto di riferimento."])}
    }
  },
  "HelpAnodic": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La collezione anodizzata offre un effetto anodizzato e si abbina perfettamente alla gamma di prodotti Alusta."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nella collezione anodizzata non è possibile scegliere una finitura laccata."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una rappresentazione digitale dei colori non è mai accurata. Chiedi quindi al tuo rivenditore di visualizzare campioni del colore e della finitura desiderati."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La collezione anodizzata offre un effetto anodizzato e si abbina perfettamente alla gamma di prodotti Alusta.."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nella collezione anodizzata non è possibile scegliere una finitura laccata."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una rappresentazione digitale dei colori non è mai accurata. Assicurati sempre di fornire campioni ai tuoi clienti."])}
    }
  },
  "OfferteFormulier": {
    "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contattaci"])},
    "MainTitleVerdouw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati del cliente"])},
    "SubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande su questa configurazione Alusta?"])},
    "voornaam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "naam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
    "straat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via + numero"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
    "gemeente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
    "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
    "welkeDealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attraverso quale rivenditore ci ha trovato?"])},
    "bedankt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie per la tua richiesta. Ti contatteremo al più presto possibile!"])}
  },
  "SelecteerKleur": {
    "structuur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strutturata"])},
    "mat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opaca"])},
    "glanzend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucida"])}
  },
  "ColorList": {
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 strutturata Nero carbone"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL"])},
    "Anodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodic"])},
    "Idem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idem"])},
    "AllColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri colori"])},
    "DefaultMat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 opaco Nero carbone"])},
    "Inox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciaio Inossidabile"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 Nero carbone"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9016 Bianco traffico"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9010 Bianco puro"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 7016 Grigio antracite"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 7021 Grigio nero"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Natura"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Saphire"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Malachite"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Champagne"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Gold"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Bronze"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Brown"])}
  },
  "Error": {
    "glasstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "Referenties": {
    "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimenti dell'ordine"])},
    "KlantRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo riferimento d'ordine"])},
    "Referentie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo riferimento della riga d'ordine"])}
  },
  "Pricing": {
    "btw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolo del prezzo esclusa l'IVA e i costi di installazione."])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In totale"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotale"])},
    "frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alusta kit (porte/pannello)"])},
    "lak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplemento laccatura"])},
    "horizontalDividers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suddivisioni orizzontali"])},
    "verticalDividers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suddivisioni verticali"])},
    "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pannello"])},
    "AMT200300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT maniglia di trazione 300mm"])},
    "AMT200500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT maniglia di trazione 500mm"])},
    "AMT200700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT maniglia di trazione 700mm"])},
    "AMT201200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT maniglia di trazione 1200mm"])},
    "AMUR200300SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ maniglia di trazione 300mm"])},
    "AMUR100380SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ maniglia di trazione 380mm"])},
    "AMUR200500SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ maniglia di trazione 500mm"])},
    "AMUR200700SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ maniglia di trazione 700mm"])},
    "AMUR201200SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ maniglia di trazione 1200mm"])},
    "AMUR302000SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ maniglia di trazione 2000mm"])},
    "ASCS01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASCS01 Serratura a cilindro incassata escluso il cilindro"])},
    "ASHG01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG01 maniglia ad angolo 150mm"])},
    "ASHG1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG1200 maniglia ad angolo 1200mm"])},
    "ASHG2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG2000 maniglia ad angolo 2000mm"])},
    "ASHG300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG300 maniglia ad angolo 300mm"])},
    "ASHG500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG500 maniglia ad angolo 500mm"])},
    "ASHGMAAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHGMAAT maniglia ad angolo su misura"])},
    "ASTOOL1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL1 Alusta strumento 1"])},
    "ASTOOL1XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL1XL Alusta strumento 1XL"])},
    "ASTOOL2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL2 Alusta strumento 2"])},
    "ASTOOL2XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL2XL Alusta strumento 2XL"])},
    "V515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V515 bocchetta della serratura"])},
    "V017S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V017S cilindro con chiavi identiche incluso 3 chiavi"])},
    "V017D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V017D cilindro non identico incluso 3 chiavi"])},
    "AS522HG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS522HG maniglia ad angolo 150mm serratura centrale"])},
    "AS522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS522 serratura centrale"])},
    "V532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V532 piastra per serratura"])},
    "061_7168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["061.7168 chiavistello scorrevole laterale"])},
    "NXMG01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Chiusura magnetica"])},
    "dk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telaio porta"])},
    "NXCS01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia della porta con serratura"])},
    "NEXDIVH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" NEX Suddivisioni orizzontali T-profilo"])},
    "NEXDIVV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Suddivisioni verticali T-profilo"])},
    "NEXEGM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia ergonomica a L su misura"])},
    "NEXEG150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia ergonomica a L 150mm"])},
    "NEXEG300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia ergonomica a L 300mm"])},
    "NEXEG500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia ergonomica a L 500mm"])},
    "NEXEG1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia ergonomica a L 1200mm"])},
    "NEXEG2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia ergonomica a L 2000mm"])},
    "NEXHGM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia a L su misura"])},
    "NEX05L": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Cerniere, DIN Left"])},
    "NEX05R": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Cerniere, DIN Right"])},
    "DF05L": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DF Cerniere, DIN Left"])},
    "DF05R": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DF Cerniere, DIN Right"])},
    "NEXHG150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia a L 150mm"])},
    "NEXHG300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia a L 300mm"])},
    "NEXHG500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia a L 500mm"])},
    "NEXHG1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia a L 1200mm"])},
    "NEXHG2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX maniglia a L 2000mm"])}
  },
  "Debug": {
    "afmeting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["glasmaat: ", _interpolate(_list(0)), " x ", _interpolate(_list(1))])}
  }
}