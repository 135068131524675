<template>
  <div>
    <div class="text-center mb-3">
      <a
        :href="$t('HelpDeurmaten.RekenvoorbeeldLink')"
        target="_blank"
        class="btn btn-white border-light shadow-sm rounded-pill font-egdl"
        >{{ $t("HelpDeurmaten.Rekenvoorbeeld") }}</a
      >
    </div>
    <BaseInfo :msg="$t('HelpDeurmaten.Info01')" />
    <div class="row align-items-center">
      <div class="col-4">
        <BaseInfo :msg="$t('HelpDeurmaten.Info02')" />
        <ul style="font-size: 0.8em">
          <li>
            {{ $t("HelpDeurmaten.Deur") }} | {{ $t("HelpDeurmaten.vloer") }} =
            14 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.Deur") }} | {{ $t("HelpDeurmaten.plafond") }} =
            5 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.Deur") }} | {{ $t("HelpDeurmaten.muur") }} = 4
            mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.Deur") }} | {{ $t("HelpDeurmaten.deur") }} = 4
            mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.Deur") }} | {{ $t("HelpDeurmaten.paneel") }} =
            4 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.Paneel") }} |
            {{ $t("HelpDeurmaten.paneel") }} = 2 mm
          </li>
          <li>
            {{ $t("HelpDeurmaten.paneel") }} | {{ $t("HelpDeurmaten.muur") }} =
            2 mm
          </li>
        </ul>
      </div>
      <div class="col-6">
        <img src="@/assets/Help/helpSpelingen.png" class="img-fluid" />
      </div>
    </div>
    <BaseInfo :msg="$t('HelpDeurmaten.Info03')" />
    <BaseInfo :msg="$t('HelpDeurmaten.Info04')" />
  </div>
</template>
<script>
export default {
  name: "HelpDeurmaten",
};
</script>
