<template>
  <div class="row align-items-center mb-3">
    <div class="col-5 text-break" v-html="$t(msg)"></div>
    <div class="col-7">
      <ul class="flex-container">
        <li
          :class="n <= score ? 'bg-primary' : 'bg-white'"
          class="border border-dark rounded flex-item"
          v-for="n in max"
          v-bind:key="n"
        ></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "VisualizeGrade",
  props: {
    msg: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      default: 5,
    },
    score: {
      type: Number,
      default: 5,
    },
  },
};
</script>
<style>
.flex-container {
  /* We first create a flex layout context */
  display: flex;
  /* Then we define the flow direction 
     and if we allow the items to wrap 
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
  /* Then we define how is distributed the remaining space */
  justify-content: space-around;
  padding: 0;
  margin: 0;
  list-style: none;
}
.flex-item {
  width: 25px;
  height: 25px;
}
</style>
