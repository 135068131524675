<template>
  <div>
    <div class="text-center mb-3 mt-5">
      <a
        :href="$t('HelpDeurkader.RekenvoorbeeldLink')"
        target="_blank"
        class="btn btn-white border-light shadow-sm rounded-pill font-egdl"
        >{{ $t("HelpDeurkader.Rekenvoorbeeld") }}</a
      >
    </div>
    <!-- NEX -->
    <SubTitle msg="HelpDeurkader.NEXTitle" />
    <div class="px-3">
      <div>
        <img src="@/assets/Help/SpelingenNEX.png" class="img-fluid" />
      </div>
      <div>
        <strong>{{ $t("HelpDeurkader.standaard") }}</strong>
      </div>
      <div class="row align-items-center">
        <div class="col-6">
          <ul style="font-size: 0.8em">
            <li>
              {{ $t("HelpDeurmaten.Opening") }} |
              {{ $t("HelpDeurmaten.zijstijlen") }} = 1,5 mm
            </li>
            <li>
              {{ $t("HelpDeurmaten.Opening") }} |
              {{ $t("HelpDeurmaten.bovenstijl") }} = 3 mm
            </li>
            <li>{{ $t("HelpDeurmaten.Deurkaderbreedte") }} = 15 mm</li>
          </ul>
        </div>
        <div class="col-6">
          <ul style="font-size: 0.8em">
            <li>
              {{ $t("HelpDeurmaten.deur") }} |
              {{ $t("HelpDeurmaten.zijstijlen") }} = 2 mm - 3 mm
            </li>
            <li>
              {{ $t("HelpDeurmaten.deur") }} |
              {{ $t("HelpDeurmaten.bovenstijl") }} = 2 mm
            </li>
            <li>
              {{ $t("HelpDeurmaten.deur") }} | {{ $t("HelpDeurmaten.vloer") }} =
              8 mm
            </li>
            <li>{{ $t("HelpDeurmaten.Deurkaderbreedte") }} = 15 mm</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <img src="@/assets/NEX05.png" class="img-fluid" />
        </div>
        <div class="col-9">
          <BaseInfo :msg="$t('HelpDeurkader.NexInfo01')" />
          <div v-if="$store.state.token == 'INVALID'">
            <BaseInfo :msg="$t('HelpDeurkader.Nex.Particulier.Info01')" />
            <BaseInfo :msg="$t('HelpDeurkader.Nex.Particulier.Info02')" />
          </div>
          <div v-if="$store.state.token != 'INVALID'">
            <BaseInfo :msg="$t('HelpDeurkader.Nex.Dealer.Info01')" />
            <BaseInfo :msg="$t('HelpDeurkader.Nex.Dealer.Info02')" />
          </div>
        </div>
      </div>
    </div>
    <!-- END NEX -->
    <!-- GP -->
    <SubTitle msg="HelpDeurkader.GPTitle" />
    <div class="px-3">
      <div>
        <img src="@/assets/Help/SpelingenGP.png" class="img-fluid" />
      </div>
      <div>
        <strong>{{ $t("HelpDeurkader.standaard") }}</strong>
      </div>
      <div class="row align-items-center">
        <div class="col-6">
          <ul style="font-size: 0.8em">
            <li>
              {{ $t("HelpDeurmaten.Opening") }} |
              {{ $t("HelpDeurmaten.zijstijlen") }} = 1,5 mm
            </li>
            <li>
              {{ $t("HelpDeurmaten.Opening") }} |
              {{ $t("HelpDeurmaten.bovenstijl") }} = 50 mm
            </li>
            <li>{{ $t("HelpDeurmaten.Deurkaderbreedte") }} = 40 mm</li>
          </ul>
        </div>
        <div class="col-6">
          <ul style="font-size: 0.8em">
            <li>
              {{ $t("HelpDeurmaten.deur") }} |
              {{ $t("HelpDeurmaten.zijstijlen") }} = 2 mm - 3 mm
            </li>
            <li>
              {{ $t("HelpDeurmaten.deur") }} |
              {{ $t("HelpDeurmaten.bovenstijl") }} = 2 mm
            </li>
            <li>
              {{ $t("HelpDeurmaten.deur") }} | {{ $t("HelpDeurmaten.vloer") }} =
              8 mm
            </li>
            <li>{{ $t("HelpDeurmaten.Deurkaderbreedte") }} = 40 mm</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <img src="@/assets/DF05.png" class="img-fluid" />
        </div>
        <div class="col-9">
          <BaseInfo :msg="$t('HelpDeurkader.GPInfo01')" />
          <div v-if="$store.state.token == 'INVALID'">
            <BaseInfo :msg="$t('HelpDeurkader.GP.Particulier.Info01')" />
            <BaseInfo :msg="$t('HelpDeurkader.GP.Particulier.Info02')" />
          </div>
          <div v-if="$store.state.token != 'INVALID'">
            <BaseInfo :msg="$t('HelpDeurkader.GP.Dealer.Info01')" />
            <BaseInfo :msg="$t('HelpDeurkader.GP.Dealer.Info02')" />
          </div>
        </div>
      </div>
    </div>
    <!-- END GP -->
    <!-- GEEN -->
    <SubTitle msg="HelpDeurkader.GeenTitle" />
    <div class="px-3">
      <div>
        <strong>{{ $t("HelpDeurkader.standaard") }}</strong>
      </div>
      <div class="row align-items-center">
        <div class="col-6">
          <img src="@/assets/Help/SpelingenGeen.png" class="img-fluid" />
        </div>
        <div class="col-6">
          <ul style="font-size: 0.8em">
            <li>
              {{ $t("HelpDeurmaten.Opening") }} |
              {{ $t("HelpDeurmaten.zijstijlen") }} = 2 mm - 3 mm
            </li>
            <li>
              {{ $t("HelpDeurmaten.Opening") }} |
              {{ $t("HelpDeurmaten.bovenstijl") }} = 2 mm
            </li>
            <li>
              {{ $t("HelpDeurmaten.deur") }} | {{ $t("HelpDeurmaten.vloer") }} =
              8 mm
            </li>
          </ul>
        </div>
      </div>
      <p class="small">{{ $t("HelpDeurkader.GeenInfo01") }}</p>
    </div>
    <!-- END GEEN -->
  </div>
</template>
<script>
export default {
  name: "HelpDeurkader",
};
</script>
