<template>
  <div class="mb-5">
    <div class="row align-items-center mb-3">
      <div class="col-8 p-5">
        <strong>{{ $t("HelpGreeptype.Titel01") }}</strong>
        <p>{{ $t("HelpGreeptype.Info01") }}</p>
        <VisualizeGrade msg="Base.Design" :score="5" :max="5" />
        <VisualizeGrade
          msg="Base.Gebruiksvriendelijkheid"
          :score="3"
          :max="5"
        />
      </div>
      <div class="col-4">
        <img src="@/assets/Help/HelpNEXHG.png" class="img-fluid" />
      </div>
    </div>

    <div class="row align-items-center mb-3">
      <div class="col-8 p-5">
        <strong>{{ $t("HelpGreeptype.Titel02") }}</strong>
        <p>{{ $t("HelpGreeptype.Info02") }}</p>
        <VisualizeGrade msg="Base.Design" :score="3" :max="5" />
        <VisualizeGrade
          msg="Base.Gebruiksvriendelijkheid"
          :score="4"
          :max="5"
        />
      </div>
      <div class="col-4">
        <img src="@/assets/Help/HelpNEXEG.png" class="img-fluid" />
      </div>
    </div>

    <div class="row align-items-center mb-3">
      <div class="col-8 p-5">
        <strong>{{ $t("HelpGreeptype.Titel03") }}</strong>
        <p>{{ $t("HelpGreeptype.Info03") }}</p>
        <VisualizeGrade msg="Base.Design" :score="4" :max="5" />
        <VisualizeGrade
          msg="Base.Gebruiksvriendelijkheid"
          :score="4"
          :max="5"
        />
      </div>
      <div class="col-4">
        <img src="@/assets/Help/HelpNXCS01.png" class="img-fluid" />
      </div>
    </div>

    <div class="row align-items-center mb-3">
      <div class="col-8 p-5">
        <strong>{{ $t("HelpGreeptype.Titel04") }}</strong>
        <p>{{ $t("HelpGreeptype.Info04") }}</p>
        <VisualizeGrade msg="Base.Design" :score="5" :max="5" />
        <VisualizeGrade
          msg="Base.Gebruiksvriendelijkheid"
          :score="4"
          :max="5"
        />
      </div>
      <div class="col-4">
        <img src="@/assets/Help/HelpAmurSQ.png" class="img-fluid" />
      </div>
    </div>

    <div class="row align-items-center mb-3">
      <div class="col-8 p-5">
        <strong>{{ $t("HelpGreeptype.Titel05") }}</strong>
        <p>{{ $t("HelpGreeptype.Info05") }}</p>
        <VisualizeGrade msg="Base.Design" :score="3" :max="5" />
        <VisualizeGrade
          msg="Base.Gebruiksvriendelijkheid"
          :score="5"
          :max="5"
        />
      </div>
      <div class="col-4">
        <img src="@/assets/Help/HelpAMT.png" class="img-fluid" />
      </div>
    </div>
    <p class="small px-3">{{ $t("HelpGreeptype.Info06") }}</p>
  </div>
</template>
<script>
export default {
  name: "HelpGreeptype",
};
</script>
