import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import i18n from "./i18n";

import SubTitle from "@/components/SubTitle.vue";
import MainTitle from "@/components/MainTitle.vue";
import BaseInfo from "@/components/BaseInfo.vue";
import ButtonQuestion from "@/components/ButtonQuestion.vue";
import BigButton from "@/components/BigButton.vue";
import NumButton from "@/components/NumButton.vue";
import SmallButton from "@/components/SmallButton.vue";
import VisualizeGrade from "@/components/VisualizeGrade.vue";

const app = createApp(App);
app.use(i18n).use(store).use(router);
app.component("SubTitle", SubTitle);
app.component("MainTitle", MainTitle);
app.component("BaseInfo", BaseInfo);
app.component("ButtonQuestion", ButtonQuestion);
app.component("BigButton", BigButton);
app.component("NumButton", NumButton);
app.component("SmallButton", SmallButton);
app.component("VisualizeGrade", VisualizeGrade);
app.mount("#app");
