<template>
  <div>
    <div v-if="$store.state.token == 'INVALID'">
      <BaseInfo :msg="$t('HelpTrekkermetslot.Particulier.Info01')" />
    </div>
    <div v-if="$store.state.token != 'INVALID'">
      <BaseInfo :msg="$t('HelpTrekkermetslot.Dealer.Info01')" />
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpTrekkerMetSlot",
};
</script>
