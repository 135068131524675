<template>
  <div class="p-3">
    <BaseInfo :msg="$t('HelpHingeColor.Info01')" />
  </div>
</template>
<script>
export default {
  name: "HelpScharnierKleur",
};
</script>
