<template>
  <!-- ALUSTA FLEX-->
  <div v-if="$store.state.productAssortiment == 0">
    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo :msg="$t('HelpDeurgrepen.Info01')" />
        <div class="mb-6"></div>
        <BaseInfo :msg="$t('HelpDeurgrepen.Info02')" />
      </div>
      <div class="col-6 text-center">
        <img src="@/assets/Help/helpDeurgreep.png" style="height: 150px" />
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo :msg="$t('HelpDeurgrepen.Info03')" />
      </div>
      <div class="col-6 text-center">
        <img
          src="@/assets/Help/HelftLengteHoekgreepPlus68.png"
          style="height: 300px"
        />
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo :msg="$t('HelpDeurgrepen.Info04')" />
      </div>
      <div class="col-6 text-center">
        <img
          src="@/assets/Help/HelftLengteHoekgreep.png"
          style="height: 300px"
        />
      </div>
    </div>
  </div>
  <!-- ALUSTA NEX-->
  <div v-if="$store.state.productAssortiment == 1">
    <div v-if="$store.getters.isLGreepNEXHG || $store.getters.isLGreepNEXEG">
      <div class="row align-items-center">
        <div class="col-6">
          <BaseInfo :msg="$t('HelpPositiegreep.Info01')" />
        </div>
        <div class="col-6 text-center">
          <img
            src="@/assets/Help/HelpPositieLgrepen.png"
            style="height: 200px"
          />
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-6">
          <BaseInfo :msg="$t('HelpPositiegreep.Info02')" />
        </div>
        <div class="col-6 text-center">
          <img
            src="@/assets/Help/HelpPositieLgrepen2.png"
            style="height: 150px"
          />
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-6">
          <BaseInfo :msg="$t('HelpPositiegreep.Info03')" />
        </div>
        <div class="col-6 text-center">
          <img
            src="@/assets/Help/HelpPositieLgrepen3.png"
            style="height: 150px"
          />
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-6">
          <BaseInfo :msg="$t('HelpPositiegreep.Info04')" />
        </div>
        <div class="col-6 text-center">
          <img
            src="@/assets/Help/HelpPositieLgrepen4.png"
            style="height: 150px"
          />
        </div>
      </div>
    </div>
    <!-- Deurkruk-->
    <div v-if="$store.getters.isKantelkruk">
      <div class="row align-items-center">
        <div class="col-6">
          <BaseInfo :msg="$t('HelpPositiegreep.Info05')" />
          <BaseInfo :msg="$t('HelpPositiegreep.Info06')" />
        </div>
        <div class="col-6 text-center">
          <img
            src="@/assets/Help/HelpPositieKantelkruk.png"
            style="height: 200px"
          />
        </div>
      </div>
    </div>
    <!-- Trekkers-->
    <div v-if="$store.getters.isAMT || $store.getters.isAMURSQ">
      <div class="row align-items-center">
        <div class="col-6">
          <BaseInfo :msg="$t('HelpPositiegreep.Info07')" />
          <BaseInfo :msg="$t('HelpPositiegreep.Info08')" />
        </div>
        <div class="col-6 text-center">
          <img
            src="@/assets/Help/HelpPositieAMURSQenAMT.png"
            style="height: 200px"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpDeurgrepen",
};
</script>
