<template>
  <div class="color-select-all">
    <!-- Display Selected Color & Name (Click to Open Dropdown) -->
    <div class="selected-box form-control" @click="toggleDropdown">
      <span class="color-circle" :style="{ backgroundColor: selectedHex }">
        &nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span>{{ selectedName }}</span>
      <span class="ps-5 dropdown-toggle"></span>
    </div>

    <!-- Dropdown List -->
    <div v-if="showDropdown" class="dropdown" ref="dropdown">
      <!-- Input for Searching -->
      <input
        ref="searchInputBox"
        type="text"
        v-model="searchQuery"
        placeholder="Search..."
        class="form-control mb-3"
        @click.stop
      />
      <ul class="dropdown-list">
        <li
          v-for="item in filteredOptions"
          :key="item.index"
          @click="selectItem(item)"
          class="dropdown-item"
        >
          <!-- Color Circle -->
          <span class="color-circle" :style="{ backgroundColor: item.hex }">
            &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>{{ item[$i18n.locale] }}</span>
        </li>
      </ul>
    </div>
    <!-- Hidden Input to Hold Selected Index -->
    <input type="hidden" :value="selectedIndex" />
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    defaultIndex: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searchQuery: "",
      selectedIndex: this.defaultIndex,
      selectedName: "",
      selectedHex: "",
      showDropdown: false,
    };
  },
  computed: {
    filteredOptions() {
      if (!this.searchQuery) return this.options;
      return this.options.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdown);
    this.setDefaultSelection();
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      this.$nextTick(() => {
        if (this.showDropdown) {
          this.$refs.searchInputBox.focus();
        }
      });
    },
    selectItem(item) {
      this.selectedIndex = item.index;
      this.selectedName = item.name;
      this.selectedHex = item.hex;
      this.showDropdown = false; // Hide dropdown after selection
      this.$store.commit("setExtraLakKleur", item.index);
      this.$emit("select", item.index); // Emit the selected index
    },
    closeDropdown(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },
    setDefaultSelection() {
      if (this.defaultIndex !== null) {
        const defaultItem = this.options.find(
          (item) => item.index === this.defaultIndex
        );
        if (defaultItem) {
          this.selectedName = defaultItem.name;
          this.selectedHex = defaultItem.hex;
        }
      }
    },
  },
};
</script>

<style scoped>
.color-select-all {
  position: relative;
  width: 100%;
}
.dropdown {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-top: 2px;
  padding: 0;
  z-index: 999;
}
.dropdown-list {
  list-style: none;
  padding: 0;
}

.dropdown-item {
  cursor: pointer;
}

.color-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0.5em;
  margin-right: 1em;
}
</style>
