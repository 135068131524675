<template>
  <div class="p-3">
    <div class="row d-flex align-items-stretch border-top border-light pt-3">
      <div class="col-6 d-flex flex-column justify-content-end">
        <h3 class="mb-3">{{ $t("Deurkader.SpelingSlotkastzijde") }}</h3>
        <div class="m-0 mb-3 p-0 row g-0 align-items-center">
          <div class="col-9">
            <input
              type="number"
              id="NexRechts"
              class="form-control rounded-pill"
              v-model="NexRechts"
            />
          </div>
          <div class="col-3">&nbsp;mm</div>
        </div>
      </div>
      <div class="col-6 d-flex flex-column justify-content-end">
        <h3 class="mb-3">{{ $t("Deurkader.SpelingBoven") }}</h3>
        <div class="m-0 mb-3 p-0 row g-0 align-items-center">
          <div class="col-9">
            <input
              type="number"
              id="NexBoven"
              class="form-control rounded-pill"
              v-model="NexBoven"
            />
          </div>
          <div class="col-3">&nbsp;mm</div>
        </div>
      </div>
      <div class="col-6 d-flex flex-column justify-content-end">
        <h3 class="mb-3">{{ $t("Deurkader.SpelingOnder") }}</h3>
        <div class="m-0 mb-3 p-0 row g-0 align-items-center">
          <div class="col-9">
            <input
              type="number"
              id="NexOnder"
              class="form-control rounded-pill"
              v-model="NexOnder"
            />
          </div>
          <div class="col-3">&nbsp;mm</div>
        </div>
      </div>
    </div>
  </div>
  <BaseInfo :msg="$t('Deurkader.SpelingInfo01')" :error="false" />
</template>
<script>
export default {
  name: "NexSpeling",
  components: {},
  data() {
    return {};
  },
  computed: {
    NexRechts: {
      get() {
        return this.$store.state.deurkaderSpeling.NexRechts;
      },
      set(value) {
        this.$store.commit("setDeurkaderSpeling", {
          id: 1,
          value: value,
        });
        this.$store.commit(
          "setBreedte",
          this.$store.state.deurkadermaat.Breedte
        );
      },
    },
    NexOnder: {
      get() {
        return this.$store.state.deurkaderSpeling.NexOnder;
      },
      set(value) {
        this.$store.commit("setDeurkaderSpeling", {
          id: 2,
          value: value,
        });
        this.$store.commit("setHoogte", this.$store.state.deurkadermaat.Hoogte);
      },
    },
    NexBoven: {
      get() {
        return this.$store.state.deurkaderSpeling.NexBoven;
      },
      set(value) {
        this.$store.commit("setDeurkaderSpeling", {
          id: 3,
          value: value,
        });
        this.$store.commit("setHoogte", this.$store.state.deurkadermaat.Hoogte);
      },
    },
  },
  methods: {},
};
</script>
