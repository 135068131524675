<template>
  <!-- ALUSTA FLEX-->
  <div v-if="$store.state.productAssortiment == 0">
    <div class="row align-items-center">
      <div class="col-12">
        <BaseInfo :msg="$t(`HelpOnderverdeling.${visitor}.Info03`)" />
      </div>
      <div class="col-4 text-center">
        <img src="@/assets/Help/A.png" style="height: 150px" />
      </div>
      <div class="col-4 text-center">
        <img src="@/assets/Help/B.png" style="height: 150px" />
      </div>
      <div class="col-4 text-center">
        <img
          src="@/assets/Help/horizontaleOnderverdelingenMiddenDeugreep.png"
          style="height: 150px"
        />
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-12">
        <BaseInfo :msg="$t(`HelpOnderverdeling.${visitor}.Info04`)" />
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo :msg="$t(`HelpOnderverdeling.${visitor}.Info01`)" />
      </div>
      <div class="col-6 text-center">
        <img src="@/assets/Help/helpVerticaal.png" style="height: 150px" />
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo :msg="$t(`HelpOnderverdeling.${visitor}.Info02`)" />
      </div>
      <div class="col-6 text-center">
        <img src="@/assets/Help/helpHorizontaal.png" style="height: 150px" />
      </div>
    </div>
  </div>
  <!-- ALUSTA NEX-->
  <div v-if="$store.state.productAssortiment == 1">
    <div class="flex-container-onderverdeling">
      <div>
        <img
          src="@/assets/Help/HelpNexKaderverdelingen01.png"
          class="img-fluid"
        />
      </div>
      <div>
        <img
          src="@/assets/Help/HelpNexKaderverdelingen02.png"
          class="img-fluid"
        />
      </div>
      <div>
        <img
          src="@/assets/Help/HelpNexKaderverdelingen03.png"
          class="img-fluid"
        />
      </div>
      <div>
        <img
          src="@/assets/Help/HelpNexKaderverdelingen04.png"
          class="img-fluid"
        />
      </div>
      <div>
        <img
          src="@/assets/Help/HelpNexKaderverdelingen05.png"
          class="img-fluid"
        />
      </div>
    </div>
    <div class="flex-container-onderverdeling">
      <div>
        <img
          src="@/assets/Help/HelpNexKaderverdelingen06.png"
          class="img-fluid"
        />
      </div>
      <div>
        <img
          src="@/assets/Help/HelpNexKaderverdelingen07.png"
          class="img-fluid"
        />
      </div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <BaseInfo :msg="$t(`HelpOnderverdeling.Nex.${visitor}.Info01`)" />
    <BaseInfo :msg="$t(`HelpOnderverdeling.Nex.${visitor}.Info02`)" />
    <BaseInfo :msg="$t(`HelpOnderverdeling.Nex.${visitor}.Info03`)" />
    <BaseInfo :msg="$t(`HelpOnderverdeling.Nex.${visitor}.Info04`)" />
    <BaseInfo :msg="$t(`HelpOnderverdeling.Nex.${visitor}.Info05`)" />
    <BaseInfo :msg="$t(`HelpOnderverdeling.Nex.${visitor}.Info06`)" />
    <BaseInfo :msg="$t(`HelpOnderverdeling.Nex.${visitor}.Info07`)" />
    <BaseInfo :msg="$t(`HelpOnderverdeling.Nex.${visitor}.Info08`)" />
    <BaseInfo :msg="$t(`HelpOnderverdeling.Nex.${visitor}.Info09`)" />
  </div>
</template>
<script>
export default {
  name: "HelpOnderverdelingen",
  data() {
    return {
      visitor: "Particulier",
    };
  },
  mounted: function () {
    this.visitor =
      this.$store.state.token == "INVALID" ? "Particulier" : "Dealer";
  },
};
</script>
<style>
.flex-container-onderverdeling {
  /* We first create a flex layout context */
  display: flex;
  flex-direction: row;
  /*flex-wrap: wrap;*/
  justify-content: space-around;
  padding: 0;
  margin: 0;
}
.flex-container-onderverdeling div {
  width: 100%;
}
</style>
