export default {
  "Base": {
    "Links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gauche"])},
    "Rechts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit"])},
    "Verticaal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
    "Horizontaal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez visualisation"])},
    "greeplengte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur de la poignée"])},
    "Design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "Gebruiksvriendelijkheid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilité d’utilisation"])}
  },
  "MainTitle": {
    "Deuren/panelen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portes/panneaux"])},
    "Kaderverdeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croisillons"])},
    "Deurgrepen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignées"])},
    "Glas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verre"])},
    "Extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
    "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur et finition"])},
    "Pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul du prix"])},
    "Deurkaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadre de porte"])},
    "ScharnierType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de charnière"])}
  },
  "SubTitle": {
    "Producttype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de produit"])},
    "Deurtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de porte"])},
    "Draairichting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens de rotation"])},
    "Openingsrichting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens d'ouverture"])},
    "Deurmaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions de la porte/du panneau"])},
    "Panelen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre et dimensions des panneaux supplémentaires"])},
    "Draaipunt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance du pivot de la porte"])},
    "Aantal/positie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre/position croisillons"])},
    "Greeptype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de poignée"])},
    "Maten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions"])},
    "Positie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position de la poignée"])},
    "MetMiddenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec serrure au milieu"])},
    "Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaque de verrouillage"])},
    "Glassoorten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types de verre"])},
    "Deurslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrou dans la plinthe inférieure"])},
    "SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bague de serrure"])},
    "Cilinders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylindre"])},
    "ALOType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de profil"])},
    "Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface de rembourrage"])},
    "Ralkleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur RAL"])},
    "Lakafwerking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finition de RAL"])},
    "Anodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC"])},
    "Zijspeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu de côté"])},
    "AsTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOLS"])},
    "HingeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur Charnières"])},
    "Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrou coulissant latéral"])},
    "Deurkadermaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions cadre de porte"])},
    "Scharnieren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position des charnières"])},
    "Magneetsluiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position de la fermeture magnétique"])}
  },
  "Producttype": {
    "Hydraulische": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte pivotante hydraulique"])},
    "Paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau fixe"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les combinaisons de portes et de panneaux peuvent être configurées au fur et à mesure."])},
    "Sliding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte coulissante"])}
  },
  "Deurtype": {
    "Enkel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte simple"])},
    "Dubbel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double porte"])}
  },
  "Deurmaten": {
    "Hoogte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur porte/panneau"])},
    "Breedte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur porte/panneau"])},
    "Info01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La baie pour les portes et les panneaux ci-dessus est: ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Info02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Les dimensions de la feuillure pour les dimensions de porte mentionnées ci-dessus sont: ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Error01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La hauteur maximale est ", _interpolate(_list(0)), " mm!"])},
    "Error02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La largeur maximale est ", _interpolate(_list(0)), " mm!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le poids de la porte max. est 100kg! Veuillez réduire les dimensions de la porte svp."])}
  },
  "Deurkader": {
    "Hoogte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur du cadre de porte"])},
    "Breedte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur du cadre de porte"])},
    "SpelingSlotkastzijde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extérieur côté serrure / Intérieur montant du cadre"])},
    "SpelingBoven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haut de la porte / Bas du linteau"])},
    "SpelingOnder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas de la porte / Sol"])},
    "SpelingInfo01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les jeux mentionnés ci-dessus influencent les dimensions de la porte."])},
    "Nex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX. CADRE DE PORTE"])},
    "GP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GP. CADRE DE PORTE"])},
    "Zonder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SANS CADRE DE PORTE"])},
    "Info01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Les dimensions d’épaisseur basées sur les dimensions du cadre de porte mentionnées ci-dessus, en tenant compte des jeux proposés, sont:  ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Info02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Les dimensions de la porte basées sur les dimensions du cadre de porte mentionnées ci-dessus, en tenant compte des jeux proposés, sont: ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de charnières est déterminé en fonction de la hauteur de la porte."])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La hauteur maximale est 3000 mm!"])},
    "Error02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La largeur maximale est 1500 mm!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le poids de la porte max. est 100kg! Veuillez réduire les dimensions de la porte svp."])}
  },
  "ScharnierType": {
    "Nex05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX05"])},
    "DF05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DF05"])}
  },
  "Scharnieren": {
    "Standaard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "OpMaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisé"])}
  },
  "AsTools": {
    "TextTool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la largeur de votre porte est supérieure à 1010 mm, il est recommandé d'acheter ASTOOL1 et ASTOOL2 pour régler la vitesse de fin et de fermeture et la broche supérieure."])},
    "TextToolXL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la largeur de votre porte est supérieure à 1200 mm, il est recommandé d'acheter ASTOOL1XL et ASTOOL2XL pour régler la vitesse de fin et de fermeture et la broche supérieure."])},
    "AsTool1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 1 - L: 300mm"])},
    "AsTool2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 2 - L: 300mm"])},
    "AsTool1XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 1XL - L: 600mm"])},
    "AsTool2XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 2XL - L: 600mm"])}
  },
  "Panelen": {
    "PaneelNum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Panneaux ", _interpolate(_list(0)), " (mm)"])},
    "AfwijkendeSituatie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situations déviantes"])},
    "AfwijkendeSituatieLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/afwijkende_panelen.pdf"])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La largeur maximale est 1500 mm"])},
    "Asymmetrisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asymétrique"])},
    "TProfiel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilé en T"])},
    "IProfiel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilé en I"])},
    "ProfielInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand vous choisissez une poignée AMUR SQ ou AMT et qu’ après cela vous positionner celle-ci sur un profil, nous vous conseillons de choisir le profil en I. Le profil en T est techniquement également possible mais cette solution plus chère et moins agréable à l’utilisation à cause du bord surélevé."])}
  },
  "Draaipunt": {
    "Afstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance suggérée est la distance recommandée pour les dimensions de porte ci-dessus."])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous recommandons de déplacer davantage le point de pivot. Le poste sélectionné n'est pas couvert par la garantie."])},
    "Error02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le point de pivot ne peut pas être supérieur à la moitié de la largeur de la porte (", _interpolate(_list(0)), " mm)!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le point de pivot ne peut pas être inférieur à 110 mm !"])}
  },
  "Zijspeling": {
    "Afstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu entre mur et porte coulissante"])}
  },
  "Greeptype": {
    "Hoekgreep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée d'angle"])},
    "AMURSQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée AMUR SQ"])},
    "AMT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée AMT"])},
    "NEXHG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX poignée en L"])},
    "NEXEG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX poignée en L ergonomique"])},
    "NXCS01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX poignée de porte avec serrure"])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette poignée est trop grande pour cette porte."])}
  },
  "Maten": {
    "MetMiddenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée d'angle avec serrure au milieu"])}
  },
  "Glassoort": {
    "Klaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clair (10 mm)"])},
    "Grijs10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gris (10 mm)"])},
    "Brons10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze (10 mm)"])},
    "AcideKlaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acide Clair (10 mm)"])},
    "AcideExtraKlaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acide Extra Clair (10 mm)"])},
    "Kathedraal8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kathedral (8 mm)"])},
    "Kathedraal6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kathedral petit (6 mm)"])},
    "Raywall8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raywall (8 mm)"])},
    "Klaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clair (6 mm)"])},
    "ExtraKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Clair (6 mm)"])},
    "Grijs06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gris (6 mm)"])},
    "Brons06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze (6 mm)"])},
    "Crepi06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crépi (6 mm)"])},
    "AcideKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acide Clair (6 mm)"])},
    "AcideExtraKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acide Extra Clair (6 mm)"])},
    "DarkGrey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark Grey (6 mm)"])}
  },
  "Slot": {
    "SlotX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serrure X (mm)"])},
    "0Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans serrure"])},
    "1Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec serrure"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La serrure est montée dans la plinthe basse. Pour ouvrir et verrouiller la serrure vous devrez vous accroupir."])},
    "0SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans bague de serrure"])},
    "1SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec bague de serrure"])},
    "0CilinderKleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodisé noir"])},
    "1CilinderKleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chrome poli"])},
    "0CilinderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serrures identiques"])},
    "1CilinderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serrures différentes"])},
    "0Middenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée sans serrure au milieu"])},
    "1Middenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée avec serrure au milieu"])},
    "0Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans plaque de verrouillage"])},
    "1Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec plaque de verrouillage"])},
    "0Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans verrou coulissant"])},
    "1Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrou coulissant latéral noir mat"])},
    "2Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrou coulissant latéral alu mat"])}
  },
  "Opvulvlak": {
    "0Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans surface de rembourrage"])},
    "1Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au bas de la porte"])},
    "2Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au milieu de la porte"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La surface de rembourrage se situe jusqu'à la 1ère subdivision horizontale."])}
  },
  "Knoppen": {
    "Opslaan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder en EGDesign"])},
    "Terug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour vers EGDesign sans sauvegarder"])},
    "Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "MailVerdouw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander"])}
  },
  "ErrorAlert": {
    "ErLiepIetsMis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème s'est produit!"])},
    "VulAllesIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez tout correctement!"])},
    "InvalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaise clé"])}
  },
  "HelpDeurgrepen": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position horizontale (h) de la poignée est déterminée par la distance entre le milieu de la poignée et le bord extérieur droit du cadre de porte."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position verticale (v) de la poignée est déterminée par la distance entre le milieu de la poignée et le bord extérieur inférieur du cadre de porte."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez que la position de la poignée d'angle soit comme sur la visualisation ci-contre, vous devez remplir la valeur suivante pour la position verticale : ½ x longeur de la poignée d'angle + 68"])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez que la position de la poignée d'angle soit comme sur la visualisation ci-contre, vous devez remplir la valeur suivante pour la position verticale : ½ x longeur de la poignée d'angle"])}
  },
  "HelpDeurmaten": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur de la porte = hauteur panneau fixe."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez tenir compte des jeux recommandés:"])},
    "Deur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte"])},
    "vloer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sol"])},
    "plafond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plafond"])},
    "muur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mur"])},
    "deur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte"])},
    "paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau"])},
    "Paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau"])},
    "Opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverture"])},
    "zijstijlen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poteaux latéraux"])},
    "bovenstijl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poteau supérieur"])},
    "Deurkaderbreedte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur du cadre de porte"])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les dimensions sont les plus petites largeurs et hauteurs de la baie de porte ou vient se placer la porte ou les panneaux."])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le cas d'une porte double, la largeur de la porte saisie sera doublée."])},
    "RekenvoorbeeldLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/rekenvb_configNEX_fr.pdf"])},
    "Rekenvoorbeeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez exemple de calcul"])}
  },
  "HelpDeurkader": {
    "NEXTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX. Cadre de porte"])},
    "NexInfo01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cadre de porte NEX est équipé par défaut de charnières NEX05R/L."])},
    "NexInfo02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les charnières NEX05L/R ont été spécialement développées pour être utilisées en combinaison avec le cadre de porte NEX, que vous pouvez également sélectionner dans le configurateur."])},
    "NexInfo03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de modifier la position des charnières dans le configurateur."])},
    "NexInfo04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la détermination de la position des charnières et des dimensions de la porte, nous vous conseillons d'utiliser votre cadre de porte spécifique comme point de référence."])},
    "NexInfo05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez utiliser ces charnières ailleurs, nous vous recommandons de consulter la fiche technique de la découpe sur le site web."])},
    "NexInfo06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez modifier les jeux entre l’extérieur du cadre et l’ouverture, vous devez changer les dimensions du cadre. Veuillez noter que les modifications des jeux seront visibles dans les épaisseurs calculées."])},
    "NexInfo07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cadre de porte NEX ne permet pas de combinaisons avec des parties vitrées latérales."])},
    "Nex": {
      "Particulier": {
        "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de faire changer la couleur des charnières différente des autres composants via votre détaillant."])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de commander uniquement le cadre de porte NEX. sans porte à charnières via votre détaillant."])}
      },
      "Dealer": {
        "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de modifier la couleur des charnières dans EGDesign."])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de commander uniquement le cadre de porte NEX. en supprimant la porte dans EGDesign."])}
      }
    },
    "GPTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GP. Cadre de porte"])},
    "GPInfo01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cadre de porte GP est équipé par défaut de charnières DF05R/L."])},
    "GPInfo02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les charnières DF05L/R ont été spécialement développées pour être utilisées avec notre gamme existante de cadres de porte (GP, SF, SW) ainsi qu'avec des cadres de porte en bois."])},
    "GPInfo03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de modifier la position des charnières dans le configurateur."])},
    "GPInfo04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la détermination de la position des charnières et des dimensions de la porte, nous vous conseillons d'utiliser votre cadre de porte spécifique comme point de référence."])},
    "GPInfo05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez utiliser ces charnières ailleurs, nous vous recommandons de consulter la fiche technique de la découpe sur le site web."])},
    "GPInfo06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez modifier les jeux entre l’extérieur du cadre et l’ouverture, vous devez changer les dimensions du cadre. Veuillez noter que les modifications des jeux seront visibles dans les épaisseurs calculées."])},
    "GP": {
      "Particulier": {
        "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de faire changer la couleur des charnières différente des autres composants via votre détaillant."])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de commander uniquement le cadre de porte GP. sans porte à charnières via votre détaillant."])}
      },
      "Dealer": {
        "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de modifier la couleur des charnières dans EGDesign."])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de commander uniquement le cadre de porte GP. en supprimant la porte dans EGDesign."])}
      }
    },
    "GeenTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans cadre de porte"])},
    "standaard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux standard:"])},
    "GeenInfo01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne commandez pas le cadre de porte NEX ou GP, nous vous recommandons de vérifier les jeux ci-dessus."])},
    "RekenvoorbeeldLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/rekenvb_config_fr.pdf"])},
    "Rekenvoorbeeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez exemple de calcul"])}
  },
  "HelpAsTools": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les largeurs de porte 1010 mm < x ≤ 1200 mm, il est recommandé de commander ASTOOL1 pour régler les vitesses de fin et de fermeture si vous ne disposez pas d&#39;un tournevis plat de longueur 300 mm."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les largeurs de porte 1010 mm < x ≤ 1200 mm, il est recommandé de commander ASTOOL2 pour régler la broche supérieure si vous ne disposez pas d&#39;un hexagone de taille 3 avec une longueur de 300 mm."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les largeurs de porte 1200 mm < x ≤ 1500 mm, il est recommandé de commander ASTOOL1XL pour régler les vitesses de fin et de fermeture si vous ne disposez pas d&#39;un tournevis à tête plate de longueur 600 mm."])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les largeurs de porte 1200 mm < x ≤ 1500 mm, il est recommandé de commander ASTOOL2XL pour régler la broche supérieure si vous ne disposez pas d&#39;un hexagone taille 3 de longueur 600 mm."])}
  },
  "HelpDraaipunt": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le point de rotation de la porte (a) est la distance entre le centre de la crapaudine inférieure de la porte et le bord extérieur du cadre. Si vous enregistrez cette configuration dans EGDesign, la valeur a - 19 mm apparaîtra dans le dessin, car ici la distance du point de rotation de la porte (a) est la distance entre le centre de la crapaudine inférieure et le bord extérieur du verre."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour des raisons de rigidité structurelle, nous recommandons de ne pas modifier la distance de pivot proposée. "])}
  },
  "HelpHingeColor": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En standard, nous livrons les charnières dans la même couleur que le cadre de la porte. Si vous le souhaitez, les charnières peuvent également être livrées en stock en RAL9005M ou Acier Inoxydable"])}
  },
  "HelpOnderverdeling": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position du croisillon vertical est déterminée par la distance entre le centre de la latte et le coté extérieur gauche ou droit du cadre de porte dépendant du sens de rotation choisi."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position du croisillon horizontal est déterminée par la distance entre le centre de la latte et le coté inférieur de la porte."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard, les croisillons horizontales et verticales sont placées symétriquement par rapport au verre. Les différentes surfaces vitrées entre les croisillons sont donc de même dimension. (A=A=A) (B=B=B)  Avec une subdivision asymétrique, la position est déterminée par rapport au cadre de la porte. Voir ci-dessous:"])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous voulez que la poignée de porte se trouve au milieu d'une subdivision horizontale, vous pouvez contacter votre détaillant."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position du croisillon vertical est déterminée par la distance entre le centre de la latte et le coté extérieur gauche ou droit du cadre de porte dépendant du sens de rotation choisi. Si vous enregistrez cette configuration dans EGDesign, la valeur v -19mm apparaîtra dans le dessin car il s'agit de la distance entre la ligne centrale de la latte de verre et le bord extérieur du verre."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position du croisillon horizontal est déterminée par la distance entre le centre de la latte et le coté inférieur de la porte. Si vous enregistrez cette configuration dans EGDesign, la valeur h -66mm apparaîtra dans le dessin car elle définit la distance entre la ligne centrale de la latte de verre et le bord inférieur du verre."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard, les croisillons horizontales et verticales sont placées symétriquement par rapport au verre. Les différentes surfaces vitrées entre les croisillons sont donc de même dimension. (A=A=A) (B=B=B)  Avec une subdivision asymétrique, la position est déterminée par rapport au cadre de la porte. Voir ci-dessous:"])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous voulez que la poignée de porte se trouve au milieu d'une subdivision horizontale, vous pouvez facilement l'ajuster dans EGDesign."])}
    },
    "Nex": {
      "Particulier": {
        "Info01": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Par défaut, les subdivisions horizontales et verticales sont placées symétriquement par rapport au verre. Les différentes surfaces vitrées entre les subdivisions sont donc de taille égale. (A=A=A) (B=B=B) Avec une subdivision asymétrique, la position est déterminée par rapport à l’extérieur"]), _normalize(["au bas du profil de porte. Voir ci-dessous :"])])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position de la latte verticale du verre est déterminée par la distance entre l’axe central de la latte du verre et le bord extrême gauche ou droit du cadre de porte en fonction de la direction d’ouverture choisie."])},
        "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position d’une latte horizontale du verre est déterminée par la distance entre l’axe central d’une latte du verre et le bord inférieur du cadre de porte."])},
        "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous conseillons vivement de placer les subdivisions horizontales juste au niveau de la poignée en L. Déterminez la hauteur centrale de la poignée selon vos souhaits et appliquez les formules suivantes:"])},
        "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V1 = V3 – (longueur de la poignée/2) – 12,5"])},
        "Info06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V2= V3 + (longueur de la poignée/2) + 12,5"])},
        "Info07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de prolonger une subdivision horizontale avec les poignées en L via votre détaillant."])},
        "Info08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de prolonger une subdivision horizontale avec la poignée inclinable avec serrure. Alignez le centre des deux."])},
        "Info09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible d’ajouter les subdivisions I et T de la série FLEX à votre NEX Hinge via votre détaillant."])}
      },
      "Dealer": {
        "Info01": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Par défaut, les subdivisions horizontales et verticales sont placées symétriquement par rapport au verre. Les différentes surfaces vitrées entre les subdivisions sont donc de taille égale. (A=A=A) (B=B=B) Avec une subdivision asymétrique, la position est déterminée par rapport à l’extérieur"]), _normalize(["au bas du profil de porte. Voir ci-dessous :"])])},
        "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position de la latte verticale du verre est déterminée par la distance entre l’axe central de la latte du verre et le bord extrême gauche ou droit du cadre de porte en fonction de la direction d’ouverture choisie. Lorsque vous enregistrez cette configuration dans EGDesign, la valeur h - 12 mm apparaîtra dans le dessin car elle détermine la distance de l’axe central de la latte du verre au bord extrême du verre."])},
        "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position d’une latte horizontale du verre est déterminée par la distance entre l’axe central d’une latte du verre et le bord inférieur du cadre de porte. Lorsque vous enregistrez cette configuration dans EGDesign, la valeur v - 12 mm apparaîtra dans le dessin car elle détermine la distance de l’axe central de la latte du verre au bord inférieur du verre."])},
        "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous conseillons vivement de placer les subdivisions horizontales juste au niveau de la poignée en L. Déterminez la hauteur centrale de la poignée selon vos souhaits et appliquez les formules suivantes :"])},
        "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V1 = V3 – (longueur de la poignée/2) – 12,5"])},
        "Info06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V2= V3 + (longueur de la poignée/2) +12,5"])},
        "Info07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous souhaitez que la subdivision horizontale se prolonge avec la poignée en L NEXHG (15 x 25 x2), ajoutez une poignée en L supplémentaire dans EGDesign. Placez ensuite les 2 poignées en L NEXHG juste en dessous et au-dessus de la subdivision horizontale. Dans le cas de la poignée en L NEXEG, il est possible de prolonger la subdivision. Il n’est pas nécessaire d’ajouter une poignée supplémentaire avec cette option dans EGDesign."])},
        "Info08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de prolonger une subdivision horizontale avec la poignée inclinable avec serrure. Alignez le centre des deux."])},
        "Info09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible d’ajouter les subdivisions I et T de la série FLEX à votre NEX Hinge via EGDesign. Notez que les subdivisions ne seront pas alignées avec le cadre de la porte. Elles seront plus profondes."])}
      }
    }
  },
  "HelpGreeptype": {
    "Titel01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette poignée est alignée avec l’extérieur de la porte pour un design très épuré."])},
    "Titel02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée en L ergonomique"])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette poignée dépasse de la porte pour améliorer la facilité d’utilisation."])},
    "Titel03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée inclinable avec serrure"])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette poignée inclinable dispose d’une serrure intégrée et est également très facile à utiliser."])},
    "Titel04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée AMUR SQ"])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette poignée de traction est élégante et son caractère anguleux s’accorde parfaitement avec Alusta FLEX et NEX."])},
    "Titel05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée AMT"])},
    "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette poignée de traction est élégante et son caractère arrondi optimise la facilité d’utilisation."])},
    "Info06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fermeture magnétique est toujours prévue quel que soit le choix de la poignée. Pour toutes les poignées sauf la poignée inclinable, nous avons différentes longueurs en stock. Il est également possible de commander des poignées sur mesure."])}
  },
  "HelpTrekkermetslot": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La serrure centrale est positionnée au centre de l'extracteur de porte en combinaison avec un extracteur AMURSQ/AMT. Contactez votre revendeur si vous souhaitez positionner la serrure centrale verticalement différemment."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La serrure centrale est positionnée au centre de l'extracteur de porte en combinaison avec un extracteur AMURSQ/AMT. Dans EGDesign, vous pouvez positionner la serrure centrale verticalement différemment de la position de l'extracteur de porte."])}
    }
  },
  "HelpPositieScharnieren": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de charnières est déterminé en fonction de la hauteur de la porte."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portes jusqu’à 2400 mm de haut -> 3 charnières. La distance entre le centre de la charnière et le bas de la porte est standard V1 = 150 mm. La distance entre le centre de la charnière et le haut de la porte est standard V2 = 150 mm. La distance entre le centre de la charnière et le haut de la porte est standard V3 = 340 mm."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portes supérieures à 2400 mm -> 4 charnières. La distance entre le centre de la charnière et le bas de la porte est standard V1 = 150 mm. La distance entre le centre de la charnière et le haut de la porte est standard V2 = 150 mm. La distance entre le centre de la charnière et le haut de la porte est standard V3 = 340 mm. La distance entre le centre de la charnière et le bas de la porte est standard V4 = 1050 mm."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de modifier la position des charnières dans le configurateur à vos propres risques."])},
      "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez ajouter des charnières supplémentaires, consultez votre détaillant et étudiez ensemble les spécifications techniques."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de charnières est déterminé en fonction de la hauteur de la porte."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portes jusqu’à 2400 mm de haut -> 3 charnières. La distance entre le centre de la charnière et le bas de la porte est standard V1 = 150 mm. La distance entre le centre de la charnière et le haut de la porte est standard V2 = 150 mm. La distance entre le centre de la charnière et le haut de la porte est standard V3 = 340 mm."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portes supérieures à 2400 mm -> 4 charnières. La distance entre le centre de la charnière et le bas de la porte est standard V1 = 150 mm. La distance entre le centre de la charnière et le haut de la porte est standard V2 = 150 mm. La distance entre le centre de la charnière et le haut de la porte est standard V3 = 340 mm. La distance entre le centre de la charnière et le bas de la porte est standard V4 = 1050 mm."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de modifier la position des charnières dans le configurateur à vos propres risques."])},
      "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez ajouter des charnières supplémentaires, cela doit être fait dans EGDesign. Veuillez noter que dans EGDesign, les dimensions du verre sont prises en compte."])}
    }
  },
  "HelpPositieMagneetsluiting": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position centrale standard (V) de l’aimant se trouve à 1050 mm du bas de la porte. Il est possible d’ajouter un aimant supplémentaire via votre détaillant."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position centrale standard (V) de l’aimant se trouve à 1050 mm du bas de la porte. Il est possible d’ajouter un aimant supplémentaire dans EGDesign et de le positionner."])}
    }
  },
  "HelpPositiegreep": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position verticale (V) des poignées en L est déterminée par le centre de la poignée par rapport au bord inférieur de la porte."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez la position de la poignée en L comme illustré à côté, entrez la valeur suivante dans la position verticale : ½ x longueur de la poignée d’angle + 30"])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez la position de la poignée en L ergonomique comme illustré à côté, entrez la valeur suivante dans la position verticale : ½ x longueur de la poignée d’angle + 30"])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez la position de la poignée en L comme illustré à côté, entrez la valeur suivante dans la position verticale : ½ x longueur de la poignée en L."])},
    "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position verticale (V) de la poignée inclinable avec serrure est déterminée par le centre de la poignée par rapport au bord inférieur de la porte."])},
    "Info06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec la poignée inclinable avec serrure, la position de la fermeture magnétique est automatiquement déterminée."])},
    "Info07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position verticale (V) de l’Amur SQ ou de l’AMT est déterminée par le centre de la poignée par rapport au bord inférieur de la porte."])},
    "Info08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*La position horizontale (H) de l’Amur SQ ou de l’AMT est déterminée par l’axe central de la poignée par rapport au bord droit de la porte."])}
  },
  "HelpRalkleur": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La couleur RAL 9005 Coatex est la finition Steellook standard. Cette teinte est disponible de stock, sauf rupture de stock."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La couleur RAL souhaitée n’est pas dans le configurateur? Demandez à votre revendeur quelles couleurs RAL sont possibles."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une représentation numérique des couleurs n’est jamais totalement identique à la couleur réelle. Par conséquent, demandez à votre revendeur de voir des échantillons de la couleur et de la finition souhaitée."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous donner à certains accessoires une couleur et une finition différentes ? C’est possible!"])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La couleur RAL 9005 Coatex est la finition Steellook standard. Cette teinte est disponible de stock, sauf rupture de stock."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La couleur RAL souhaitée n’est pas dans le configurateur? Via EGDesign, il est possible de sélectionner d’autres couleurs RAL."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une représentation numérique des couleurs n’est jamais totalement identique à la couleur réelle. Offrez toujours des échantillons à vos clients."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via EGDesign, il est possible d’attribuer aux accessoires une couleur et une finition différentes."])}
    },
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez tenir compte que le choix d'une couleur ou finition différente peut allonger considérablement le délai de livraison."])}
  },
  "HelpLakafwerking": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors du choix d’une couleur RAL, il est nécessaire de choisir une finition de laque souhaitée (structuré, mat, brillant)."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour obtenir l’effet d’aspect de l’acier, la laque texturée est recommandée. Demandez à votre concessionnaire des échantillons pour comparer les différentes finitions."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors du choix d’une couleur RAL, il est nécessaire de choisir une finition de laque souhaitée (structuré, mat, brillant)."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour obtenir l’effet d’aspect de l’acier, la laque texturée est recommandée. Offrez à vos clients des échantillons des différentes finitions de laque."])}
    }
  },
  "HelpScharnieren": {
    "NEXTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charnières NEX05L/R"])},
    "DF05Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charnières DF05L/R"])},
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cadre de porte NEX est équipé par défaut de charnières NEX05R/L."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de modifier la position des charnières dans le configurateur."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de faire changer la couleur des charnières différente des autres composants via votre détaillant."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de commander uniquement le cadre de porte NEX. sans porte à charnières via votre détaillant."])},
      "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cadre de porte NEX ne permet pas de combinaisons avec des parties vitrées latérales."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cadre de porte NEX est équipé par défaut de charnières NEX05R/L."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de modifier la position des charnières dans le configurateur."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de modifier la couleur des charnières dans EGDesign."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible de commander uniquement le cadre de porte NEX. en supprimant la porte dans EGDesign."])},
      "Info05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cadre de porte NEX ne permet pas de combinaisons avec des parties vitrées latérales."])},
      "InfoNEX01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les charnières NEX05L/R ont été spécialement développées pour être utilisées en combinaison avec le cadre de porte NEX, que vous pouvez également sélectionner dans le configurateur."])},
      "InfoNEX02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez utiliser ces charnières ailleurs, nous vous recommandons de consulter la fiche technique des découpes sur le site web."])},
      "InfoNEX03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la détermination de la position des charnières et des dimensions de la porte, nous vous conseillons d'utiliser votre cadre de porte spécifique comme point de référence."])},
      "InfoDF0501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les charnières DF05L/R ont été spécialement développées pour être utilisées avec notre gamme existante de cadres de porte (GP, SF, SW) ainsi qu'avec des cadres de porte en bois."])},
      "InfoDF0502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez utiliser ces charnières ailleurs, nous vous recommandons de consulter la fiche technique des découpes sur le site web."])},
      "InfoDF0503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la détermination de la position des charnières et des dimensions de la porte, nous vous conseillons d'utiliser votre cadre de porte spécifique comme point de référence."])}
    }
  },
  "HelpAnodic": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La collection anodisée se marie parfaitement avec la gamme de produits Alusta."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec la collection anodisée, il n’est pas possible de choisir une finition laquée."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une représentation numérique des couleurs n’est jamais totalement identique à la couleur réelle. Par conséquent, demandez à votre revendeur de voir des échantillons de la couleur et de la finition souhaitée."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La collection anodisée se marie parfaitement avec la gamme de produits Alusta."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec la collection anodisée, il n’est pas possible de choisir une finition laquée."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une représentation numérique des couleurs n’est jamais totalement identique à la couleur réelle. Offrez toujours des échantillons à vos clients."])}
    }
  },
  "OfferteFormulier": {
    "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
    "MainTitleVerdouw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données de client"])},
    "SubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions sur cette configuration Alusta?"])},
    "voornaam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "naam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "straat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "gemeente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "welkeDealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par quel distributeur nous avez-vous trouvés ?"])},
    "bedankt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous remercions de votre demande. Nous vous contacterons dès que possible!"])}
  },
  "SelecteerKleur": {
    "structuur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structuré"])},
    "mat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat"])},
    "glanzend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brillant"])}
  },
  "ColorList": {
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 structure Noir foncé"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL"])},
    "Anodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodic"])},
    "Idem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idem"])},
    "AllColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre couleurs"])},
    "DefaultMat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 mat Noir foncé"])},
    "Inox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acier Inoxydable"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 Noir foncé"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9016 Blanc signalisation"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9010 Blanc pur"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 7016 Gris anthracite"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 7021 Gris noir"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Natura"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Saphire"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Malachite"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Champagne"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Gold"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Bronze"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Brown"])}
  },
  "Error": {
    "glasstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "Referenties": {
    "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Références de commande"])},
    "KlantRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre référence de commande"])},
    "Referentie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre référence de la ligne de commande"])}
  },
  "Pricing": {
    "btw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul du prix hors TVA et frais d'installation"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-total"])},
    "frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit Alusta (porte/panneau)"])},
    "lak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplément peinture"])},
    "horizontalDividers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croissillons horizontales"])},
    "verticalDividers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croissillons verticales"])},
    "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface de remblage"])},
    "AMT200300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT poignée 300mm"])},
    "AMT200500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT poignée 500mm"])},
    "AMT200700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT poignée 700mm"])},
    "AMT201200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT poignée 1200mm"])},
    "AMUR200300SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ poignée 300mm"])},
    "AMUR100380SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ poignée 380mm"])},
    "AMUR200500SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ poignée 500mm"])},
    "AMUR200700SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ poignée 700mm"])},
    "AMUR201200SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ poignée 1200mm"])},
    "AMUR302000SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ poignée 2000mm"])},
    "ASCS01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASCS01 serrure avec gâche et 3 clés"])},
    "ASHG01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG01 poignée d'angle 150mm"])},
    "ASHG1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG1200 poignée d'angle 1200mm"])},
    "ASHG2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG2000 poignée d'angle 2000mm"])},
    "ASHG300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG300 poignée d'angle 300mm"])},
    "ASHG500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG500 poignée d'angle 500mm"])},
    "ASHGMAAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHGMAAT poignée d'angle sur mesure"])},
    "ASTOOL1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL1 Alusta outil 1"])},
    "ASTOOL1XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL1XL Alusta outil 1XL"])},
    "ASTOOL2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL2 Alusta outil 2"])},
    "ASTOOL2XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL2XL Alusta outil 2XL"])},
    "V515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V515 bague de serrure"])},
    "V017S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V017S cylindre à clés identiques comprenant 3 clés"])},
    "V017D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V017D cylindre à cles non identique incluant 3 clés"])},
    "AS522HG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS522HG poignée d'angle 150mm avec serrure centrale"])},
    "AS522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS522 serrure centrale"])},
    "V532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V532 plaque de verrouillage"])},
    "061_7168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["061.7168 verrou coulissant latéral"])},
    "NXMG01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Fermeture magnetique"])},
    "dk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadre de porte"])},
    "NXCS01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX poignée de porte avec serrure"])},
    "NEXDIVH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" NEX Croissillons horizontales T-profile"])},
    "NEXDIVV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Croissillons verticales T-profile"])},
    "NEXEGM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L ergonomique sur mesure"])},
    "NEXEG150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L ergonomique 150mm"])},
    "NEXEG300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L ergonomique 300mm"])},
    "NEXEG500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L ergonomique 500mm"])},
    "NEXEG1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L ergonomique 1200mm"])},
    "NEXEG2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L ergonomique 2000mm"])},
    "NEXHGM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L sur mesure"])},
    "NEX05L": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Charnières, DIN Gauche"])},
    "NEX05R": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Charnières, DIN Droite"])},
    "DF05L": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DF Charnières, DIN Gauche"])},
    "DF05R": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DF Charnières, DIN Droite"])},
    "NEXHG150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L 150mm"])},
    "NEXHG300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L 300mm"])},
    "NEXHG500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L 500mm"])},
    "NEXHG1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L 1200mm"])},
    "NEXHG2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEX Poignée en L 2000mm"])}
  },
  "Debug": {
    "afmeting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["glasmaat: ", _interpolate(_list(0)), " x ", _interpolate(_list(1))])}
  }
}