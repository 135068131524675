<template>
  <button
    class="ms-3 mb-3 border border-light shadow-sm p-2 rounded-3"
    :class="[selected ? 'bg-light' : 'bg-body']"
    :style="style"
  >
    <img :src="src" :style="imgStyle" v-if="color == ''" />
    <div
      class="my-3 border border-light shadow-sm p-2 rounded-3"
      :style="style2"
      v-if="color != ''"
    />
    <hr v-if="msg != ''" />
    <div
      v-if="msg != ''"
      style="
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        overflow: hidden;
        font-size: 0.875rem;
        padding: 0 8px;
        line-height: 1.2;
      "
      v-html="$t(msg)"
    ></div>
  </button>
</template>
<script>
export default {
  name: "BigButton",
  props: {
    msg: {
      type: String,
      default: "",
    },
    src: String,
    selected: Boolean,
    width: {
      type: Number,
      default: 25,
    },
    color: {
      type: String,
      default: "",
    },
    imgSize: {
      type: String,
      default: "150px",
    },
  },
  computed: {
    imgStyle() {
      //return "height: " + this.imgSize;
      return "width: 100%";
    },
    style() {
      return "width: " + this.width + "%; vertical-align: top";
    },
    style2() {
      if (this.color == "all") {
        return "margin: auto; width: 50px; height:50px; background-image: conic-gradient(#0e0e10,#f1f0ea,#f1ece1,#383e42,#2f3234,#D7D4D2,#7B9AA1,#75918B,#C6C49D,#B09A6A,#75654C,#514434);";
      } else if (this.color == "inox") {
        return "margin: auto; width: 50px; height:50px; background-color: #acb2b4";
      } else {
        return (
          "margin: auto; width: 50px; height:50px; background-color:" +
          this.color
        );
      }
    },
  },
};
</script>
