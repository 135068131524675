<template>
  <div class="drawScreen">
    <div v-if="$store.state.productAssortiment == 1 && false">
      <img src="@/assets/NexTempFoto.jpg" class="img-fluid" />
    </div>
    <div v-show="$store.state.productAssortiment == 0 || true">
      <canvas
        id="mainCanvas"
        class="img-fluid rounded-3"
        width="2048"
        height="1070"
      ></canvas>
      <canvas
        id="blitCanvas"
        class="img-fluid"
        width="2048"
        height="1070"
        style="display: none"
      ></canvas>
      <canvas
        id="colorCanvas"
        class="img-fluid"
        width="10"
        height="10"
        style="display: none"
      ></canvas>
    </div>
    <br />
    <button
      class="btn btn-primary rounded-pill hideOnPrint"
      v-on:click.prevent="DownloadImg()"
      v-show="$store.state.productAssortiment == 0 || true"
    >
      {{ $t("Base.Download") }}
    </button>
    &nbsp;
    <PrintButton v-show="$store.state.productAssortiment == 0 || true" />
  </div>
</template>
<script>
import PrintButton from "@/components/PrintButton.vue";
export default {
  name: "DrawScreen",
  components: {
    PrintButton,
  },
  data() {
    return {
      frontImg: new Image(),
      backImg: new Image(),
      wallImg: new Image(),
      glassImg: new Image(),
      bovenspil: new Image(),
      onderverdelingH: new Image(),
      onderverdelingV: new Image(),
      slotkast: new Image(),
      opvulvlak: new Image(),
      loaded: 0,
      mainCanvas: "",
      mainContext: "",
      scale: 0.16, //0.14 for full width use
      bottomWall: 577,
      center: 0,
      currentColor: "#0e0e10",
    };
  },
  mounted: function () {
    var self = this;
    this.bovenspil.src = require("@/assets/Draaipunt/Bovendraaispil.png");
    this.onderverdelingH.src = require("@/assets/Onderverdeling/latH.png");
    this.onderverdelingV.src = require("@/assets/Onderverdeling/latV.png");
    this.slotkast.src = require("@/assets/Extra/Slotkast.png");
    this.opvulvlak.src = require("@/assets/Onderverdeling/Opvulvlak.png");

    this.wallImg.src = require("@/assets/Interieur/Wall.png");
    this.wallImg.onload = function () {
      self.loadCounter();
    };
    this.backImg.src = require("@/assets/Interieur/Back.png");
    this.backImg.onload = function () {
      self.loadCounter();
    };
    this.frontImg.src = require("@/assets/Interieur/Front.png");
    this.frontImg.onload = function () {
      self.loadCounter();
    };
    this.glassImg.src = require("@/assets/Interieur/Glassoorten/FloatKlaar.png");
    this.glassImg.onload = function () {
      self.loadCounter();
    };

    this.mainCanvas = document.getElementById("mainCanvas");
    this.mainContext = this.mainCanvas.getContext("2d");
    this.center = this.mainCanvas.width / 2;
  },
  methods: {
    loadCounter() {
      this.loaded++;
      if (this.loaded == 4) {
        this.reDraw();
        this.$watch("$store.state.redrawFix", () => {
          var self = this;
          this.changeAllColors();
          setTimeout(function () {
            self.reDraw();
          }, 100);
        });
        this.$watch("$store.state.glassoortKeuze", () => {
          this.setGlassoort();
        });
      }
    },
    DownloadImg() {
      var link = document.createElement("a");
      link.download = "Alusta.jpg";
      link.href = this.mainCanvas.toDataURL("image/jpeg");
      link.click();
    },
    setGlassoort() {
      var self = this;
      this.glassImg.src = require(`@/assets/Interieur/Glassoorten/${
        this.$store.state.glassoort[this.$store.state.glassoortKeuze].drawimg
      }`);
      this.glassImg.onload = function () {
        console.log(self.glassImg.src);
        self.reDraw();
      };
    },
    reDraw() {
      this.mainContext.clearRect(
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );
      this.mainContext.drawImage(
        this.backImg,
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );

      this.mainContext.drawImage(
        this.glassImg,
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );

      this.drawWallGap();
      //Frame
      var x = 0;
      if (this.$store.state.productAssortiment == 1) {
        x =
          this.center - (this.$store.state.kadermaat.Breedte * this.scale) / 2;
      } else {
        x = this.center - (this.$store.state.spanmaat.Breedte * this.scale) / 2;
      }

      //Deurkader
      this.drawDK(
        this.center -
          (this.$store.state.deurkadermaat.Breedte / 2) * this.scale,
        this.bottomWall -
          this.$store.state.deurkadermaat.Hoogte * this.scale -
          (19 * this.scale) / 2,
        this.$store.state.deurkadermaat.Breedte * this.scale,
        this.$store.state.deurkadermaat.Hoogte * this.scale
      );
      //Linkse panelen
      for (var i = 0; i < this.$store.state.aantalPanelenLinks; i++) {
        //x = x + 1 * this.scale;
        this.drawAllSeparatorsH(
          x,
          this.$store.state.panelenLinks[i] * this.scale
        );
        this.drawFrame(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.panelenLinks[i] * this.scale,
          this.$store.state.kadermaat.Hoogte * this.scale
        );
        x += this.$store.state.panelenLinks[i] * this.scale;
        //x = x + 1 * this.scale;
      }

      //Deur Links
      if (
        (this.$store.state.draairichting == 0 &&
          this.$store.state.aantalDeuren == 1) ||
        this.$store.state.aantalDeuren != 1
      ) {
        this.drawAllSeparatorsH(
          x,
          this.$store.state.kadermaat.Breedte * this.scale
        );
        this.drawAllSeparatorsV(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.kadermaat.Hoogte * this.scale,
          this.$store.state.kadermaat.Breedte * this.scale
        );
        this.drawOpvulvlak(x);
        this.drawFrame(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.kadermaat.Breedte * this.scale,
          this.$store.state.kadermaat.Hoogte * this.scale
        );

        //x = x + 4 * this.scale;
        if (this.$store.state.aantalDeuren >= 1) {
          this.drawBovenspil(
            x + this.$store.state.draaipuntX * this.scale,
            this.bottomWall -
              this.$store.state.kadermaat.Hoogte * this.scale -
              19 * this.scale
          );
        }
        x += this.$store.state.kadermaat.Breedte * this.scale;
        if (this.$store.state.aantalDeuren >= 1) {
          this.drawGreep(
            x - this.$store.state.hendelPositie.x * this.scale,
            this.bottomWall - this.$store.state.hendelPositie.y * this.scale
          );
        }
        if (this.$store.state.slot == 1) {
          this.drawSlot(
            x - this.$store.state.slotX * this.scale,
            this.bottomWall
          );
        }
      }
      //gedraaide deur of dubbele deur
      if (
        this.$store.state.aantalDeuren > 1 ||
        (this.$store.state.draairichting == 1 &&
          this.$store.state.aantalDeuren == 1)
      ) {
        this.drawAllSeparatorsH(
          x,
          this.$store.state.kadermaat.Breedte * this.scale
        );
        this.drawAllSeparatorsV(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.kadermaat.Hoogte * this.scale,
          0
        );
        this.drawOpvulvlak(x);
        this.drawFrame(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.kadermaat.Breedte * this.scale,
          this.$store.state.kadermaat.Hoogte * this.scale
        );
        this.drawBovenspil(
          x +
            this.$store.state.kadermaat.Breedte * this.scale -
            this.$store.state.draaipuntX * this.scale,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            19 * this.scale
        );
        this.drawGreepMirror(
          x + this.$store.state.hendelPositie.x * this.scale,
          this.bottomWall - this.$store.state.hendelPositie.y * this.scale
        );
        //x = x + 4 * this.scale;
        x += this.$store.state.kadermaat.Breedte * this.scale;
        if (this.$store.state.slot == 1) {
          this.drawSlot(
            x -
              this.$store.state.kadermaat.Breedte * this.scale +
              this.$store.state.slotX * this.scale -
              this.slotkast.width * this.scale,
            this.bottomWall
          );
        }
      }
      //Rechtse panelen
      for (i = 0; i < this.$store.state.aantalPanelenRechts; i++) {
        //x = x + 1 * this.scale;
        this.drawAllSeparatorsH(
          x,
          this.$store.state.panelenRechts[i] * this.scale
        );
        this.drawFrame(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.panelenRechts[i] * this.scale,
          this.$store.state.kadermaat.Hoogte * this.scale
        );
        x += this.$store.state.panelenRechts[i] * this.scale;
        //x = x + 1 * this.scale;
      }
      //Furniture
      this.mainContext.drawImage(
        this.frontImg,
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );
    },
    drawBovenspil(x, y) {
      if (this.$store.state.productAssortiment == 0) {
        this.mainContext.drawImage(
          this.bovenspil,
          x - (this.bovenspil.width / 2) * this.scale,
          y,
          this.bovenspil.width * this.scale,
          this.bovenspil.height * this.scale
        );
      }
    },
    drawSlot(x, y) {
      this.mainContext.drawImage(
        this.slotkast,
        x,
        y -
          (this.frameOnder.height / 2) * this.scale -
          this.slotkast.height * this.scale,
        this.slotkast.width * this.scale,
        this.slotkast.height * this.scale
      );
    },
    drawOpvulvlak(x) {
      const onderkant =
        this.$store.state.productAssortiment == 0
          ? this.$store.state.kaderNaarGlasMaat.FlexOnder
          : this.$store.state.kaderNaarGlasMaat.NexOnder;
      const dividersExtra =
        !this.$store.state.asymmetrisch || this.$store.state.asymmetrisch == 0
          ? 0
          : onderkant * -1;
      if (this.$store.state.opvulvlak == 1) {
        let y = this.bottomWall - onderkant * this.scale;
        let w = this.$store.state.kadermaat.Breedte * this.scale;
        let h =
          (this.$store.getters.getBottomHorizontalSeperatorY + dividersExtra) *
          this.scale;
        this.mainContext.drawImage(this.opvulvlak, x, y, w, h * -1);
      }
    },
    drawGreep(x, y) {
      this.mainContext.fillStyle = this.currentColor;
      if (
        this.$store.getters.isHoekgreep ||
        this.$store.getters.isLGreepNEXHG ||
        this.$store.getters.isLGreepNEXEG
      ) {
        const w = 38;
        this.mainContext.fillRect(
          x - (w + 19) * this.scale,
          y - (this.$store.state.greeplengte / 2) * this.scale,
          w * this.scale,
          this.$store.state.greeplengte * this.scale
        );
      } else if (this.$store.getters.isAMURSQ || this.$store.getters.isAMT) {
        const w = 20;
        this.mainContext.fillRect(
          x - (w + 19) * this.scale,
          y - (this.$store.state.greeplengte / 2) * this.scale,
          w * this.scale,
          this.$store.state.greeplengte * this.scale
        );
      } else if (this.$store.getters.isKantelkruk) {
        const w = 38;
        this.mainContext.fillRect(
          x - (w + 19) * this.scale,
          y - (this.$store.state.greeplengte / 2) * this.scale,
          w * this.scale,
          this.$store.state.greeplengte * this.scale
        );
        const newX = x - (w + 19) * this.scale;
        this.mainContext.fillRect(
          newX - w * 2 * this.scale,
          y -
            (this.$store.state.greeplengte / 2) * this.scale +
            (this.$store.state.greeplengte / 4) * this.scale,
          w * 2 * this.scale,
          (this.$store.state.greeplengte / 2) * this.scale
        );
      }
    },
    drawGreepMirror(x, y) {
      this.mainContext.fillStyle = this.currentColor;
      if (
        this.$store.getters.isHoekgreep ||
        this.$store.getters.isLGreepNEXHG ||
        this.$store.getters.isLGreepNEXEG
      ) {
        const w = 38;
        this.mainContext.fillRect(
          x + 19 * this.scale,
          y - (this.$store.state.greeplengte / 2) * this.scale,
          w * this.scale,
          this.$store.state.greeplengte * this.scale
        );
      } else if (this.$store.getters.isAMURSQ || this.$store.getters.isAMT) {
        const w = 20;
        this.mainContext.fillRect(
          x + 19 * this.scale,
          y - (this.$store.state.greeplengte / 2) * this.scale,
          w * this.scale,
          this.$store.state.greeplengte * this.scale
        );
      } else if (this.$store.getters.isKantelkruk) {
        const w = 38;
        this.mainContext.fillRect(
          x + 19 * this.scale,
          y - (this.$store.state.greeplengte / 2) * this.scale,
          w * this.scale,
          this.$store.state.greeplengte * this.scale
        );
        const newX = x + 19 * this.scale;
        this.mainContext.fillRect(
          newX + w * this.scale,
          y -
            (this.$store.state.greeplengte / 2) * this.scale +
            (this.$store.state.greeplengte / 4) * this.scale,
          w * 2 * this.scale,
          (this.$store.state.greeplengte / 2) * this.scale
        );
      }
    },
    drawAllSeparatorsH(x, w) {
      for (
        var i = 0;
        i < this.$store.state.horizontaleOnderverdelingen.length;
        i++
      ) {
        this.drawSeparatorgH(
          x,
          this.$store.state.horizontaleOnderverdelingen[i] * this.scale,
          w
        );
      }
    },
    drawSeparatorgH(x, y, w) {
      const onderkant =
        this.$store.state.productAssortiment == 0
          ? this.$store.state.kaderNaarGlasMaat.FlexOnder
          : this.$store.state.kaderNaarGlasMaat.NexOnder;
      // eslint-disable-next-line no-unused-vars
      const dividersExtra =
        !this.$store.state.asymmetrisch || this.$store.state.asymmetrisch == 0
          ? 0
          : onderkant * -1;
      this.mainContext.drawImage(
        this.onderverdelingH,
        x,
        this.bottomWall - y + (this.onderverdelingH.width / 2) * this.scale,
        w,
        this.onderverdelingH.height * this.scale
      );
    },
    drawAllSeparatorsV(x, y, h, offset = 0) {
      const mirror = offset == 0 ? -1 : 1;
      for (
        var i = 0;
        i < this.$store.state.verticaleOnderverdelingen.length;
        i++
      ) {
        this.drawSeparatorgV(
          x +
            offset -
            this.$store.state.verticaleOnderverdelingen[i] *
              this.scale *
              mirror,
          y,
          h
        );
      }
    },
    drawSeparatorgV(x, y, h) {
      this.mainContext.drawImage(
        this.onderverdelingV,
        x - (this.onderverdelingV.width / 2) * this.scale,
        y,
        this.onderverdelingH.width * this.scale,
        h
      );
    },
    drawWallGap() {
      var canvas = document.getElementById("blitCanvas");
      var context = canvas.getContext("2d");
      context.globalCompositeOperation = "source-over";
      context.clearRect(0, 0, this.mainCanvas.width, this.mainCanvas.height);
      // draw a rectangle
      context.beginPath();
      context.drawImage(
        this.wallImg,
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );
      context.fillStyle = "blue";
      context.fill();

      context.globalCompositeOperation = "destination-out";
      context.beginPath();
      let b = this.$store.state.spanmaat.Breedte * this.scale;
      let h = this.$store.state.spanmaat.Hoogte * this.scale;
      let c = this.center;
      context.rect(c - b / 2, this.bottomWall - h, b, h);
      context.fillStyle = "#ff0000";
      context.fill();

      //Wall
      this.mainContext.drawImage(
        canvas,
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );
    },
    drawFrame(x, y, w, h) {
      const ctx = this.mainContext;
      const scale = this.scale;

      const topHeight = 29 * scale;
      const sideWidth = 29 * scale;
      const bottomHeight =
        this.$store.state.productAssortiment == 0 ? 79 * scale : 29 * scale;
      ctx.fillStyle = this.currentColor;

      // Top
      ctx.fillRect(x, y, w, topHeight);

      // Bottom
      ctx.fillRect(x, y + h - bottomHeight, w, bottomHeight);

      // Left Side
      ctx.fillRect(x, y, sideWidth, h);

      // Right Side
      ctx.fillRect(x + w - sideWidth, y, sideWidth, h);
    },
    drawDK(x, y, w, h) {
      if (
        this.$store.state.productAssortiment == 1 &&
        this.$store.state.deurkaderKeuze != 2
      ) {
        const ctx = this.mainContext;
        const scale = this.scale;

        const topHeight =
          this.$store.state.deurkaderKeuze == 0 ? 15 * scale : 40 * scale;
        const sideWidth =
          this.$store.state.deurkaderKeuze == 0 ? 15 * scale : 40 * scale;
        ctx.fillStyle = this.currentColor;

        // Top
        ctx.fillRect(x, y, w, topHeight);

        // Left Side
        ctx.fillRect(x, y, sideWidth, h);

        // Right Side
        ctx.fillRect(x + w - sideWidth, y, sideWidth, h);
      }
    },
    changeAllColors() {
      this.changeColor(this.bovenspil);
      this.changeColor(this.onderverdelingH);
      this.changeColor(this.onderverdelingV);
      this.changeColor(this.slotkast);
      this.changeColor(this.opvulvlak);
    },
    changeColor(img) {
      var canvas = document.getElementById("colorCanvas");
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      ctx.globalCompositeOperation = "source-in";
      if (this.$store.state.lakkleur != -1) {
        ctx.fillStyle =
          this.$store.state.lakkleuren[this.$store.state.lakkleur];
      } else {
        const selectedColor = this.$store.state.extralakkleurlist.find(
          (item) => item.index == this.$store.state.extralakkleur
        );
        ctx.fillStyle = selectedColor.hex;
      }
      this.currentColor = ctx.fillStyle;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = "source-over";
      img.src = canvas.toDataURL("image/png");
    },
  },
};
</script>
