<template>
  <div>
    <div class="row align-items-center">
      <div v-if="$store.state.token == 'INVALID'" class="col-8">
        <BaseInfo :msg="$t('HelpScharnieren.Particulier.Info01')" />
        <BaseInfo :msg="$t('HelpScharnieren.Particulier.Info02')" />
        <BaseInfo :msg="$t('HelpScharnieren.Particulier.Info03')" />
        <BaseInfo :msg="$t('HelpScharnieren.Particulier.Info04')" />
        <BaseInfo :msg="$t('HelpScharnieren.Particulier.Info05')" />
      </div>
      <div v-if="$store.state.token != 'INVALID'" class="col-8">
        <BaseInfo :msg="$t('HelpScharnieren.Dealer.Info01')" />
        <BaseInfo :msg="$t('HelpScharnieren.Dealer.Info02')" />
        <BaseInfo :msg="$t('HelpScharnieren.Dealer.Info03')" />
        <BaseInfo :msg="$t('HelpScharnieren.Dealer.Info04')" />
        <BaseInfo :msg="$t('HelpScharnieren.Dealer.Info05')" />
      </div>
      <div class="col-2">
        <img src="@/assets/NEX05.png" style="height: 150px" />
        <img src="@/assets/DF05.png" style="height: 150px" />
      </div>
    </div>
    <div v-if="$store.state.token != 'INVALID'">
      <SubTitle msg="HelpScharnieren.NEXTitle" />
      <BaseInfo :msg="$t('HelpScharnieren.Dealer.InfoNEX01')" />
      <BaseInfo :msg="$t('HelpScharnieren.Dealer.InfoNEX02')" />
      <BaseInfo :msg="$t('HelpScharnieren.Dealer.InfoNEX03')" />
      <div class="center">
        <img src="@/assets/Help/VraagtekenNEX.png" class="mx-auto d-block" />
      </div>
      <SubTitle msg="HelpScharnieren.DF05Title" />
      <BaseInfo :msg="$t('HelpScharnieren.Dealer.InfoDF0501')" />
      <BaseInfo :msg="$t('HelpScharnieren.Dealer.InfoDF0502')" />
      <BaseInfo :msg="$t('HelpScharnieren.Dealer.InfoDF0503')" />
      <div class="center">
        <img src="@/assets/Help/VraagtekenDF05.png" class="mx-auto d-block" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpScharnieren",
};
</script>
