<template>
  <!-- EXTRA //-->
  <div>
    <SubTitle
      msg="SubTitle.HingeColor"
      :showQuestion="true"
      helpProp="scharnierKleur"
    />
    <HelpScharnierKleur v-if="$store.state.helpProperty.scharnierKleur" />
    <div class="p-0 pt-2 mb-3 mt-3">
      <BigButton
        color="all"
        :src="require('@/assets/ColorCircle.png')"
        :msg="'ColorList.Idem'"
        imgSize="50px;"
        :selected="this.scharnierKleur == 0"
        v-on:click.prevent="setScharnierKleur(0)"
        :width="20"
      />
      <BigButton
        :color="$store.state.lakkleuren[0]"
        :msg="'ColorList.DefaultMat'"
        :selected="this.scharnierKleur == 1"
        v-on:click.prevent="setScharnierKleur(1)"
        :width="20"
      />
      <BigButton
        color="inox"
        :src="require('@/assets/ColorCircle.png')"
        :msg="'ColorList.Inox'"
        imgSize="50px;"
        :selected="this.scharnierKleur == 2"
        v-on:click.prevent="setScharnierKleur(2)"
        :width="20"
      />
    </div>
  </div>
  <!-- END EXTRA //-->
</template>
<script>
import HelpScharnierKleur from "@/components/help/HelpScharnierKleur.vue";
export default {
  name: "ColorSelect",
  components: {
    HelpScharnierKleur,
  },
  data() {},
  computed: {
    scharnierKleur: {
      get() {
        return this.$store.state.scharnierKleur;
      },
      set(value) {
        this.$store.commit("setScharnierKleur", value);
      },
    },
  },
  methods: {
    setScharnierKleur(index) {
      this.scharnierKleur = index;
    },
  },
};
</script>
